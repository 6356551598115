import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDevelopmentAreas, getSpecializations } from '@/actions/content'
import { getOptions, getCities, getAreas, getActivityTypes, searchData } from '@/actions/search'
import { useNavigate } from 'react-router-dom'
import { emptyFilters } from '@/constants/search'
import SearchFields from './SearchFields'
import SearchResults from './SearchResults'

const SearchBlock = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const cities = useSelector(state => state.search.cities)
	const areas = useSelector(state => state.content.areas)
	const specializations = useSelector(state => state.content.specializations)
	const studies = useSelector(state => state.search.studies)
	const workplaces = useSelector(state => state.search.workplaces)
	const activityTypes = useSelector(state => state.search.activityTypes)
	const organizations = useSelector(state => state.search.organizations)
	const results = useSelector(state => state.search.results)

	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(getOptions())
			await dispatch(getDevelopmentAreas())
			await dispatch(getSpecializations())
			await dispatch(searchData({
				query: '',
				filters: { type: '', ...emptyFilters.all }
			}))
			setTimeout(() => setLoaded(true), 500)
		}

		fetchData()
	}, [dispatch])

	const onSearch = async (payload) => {
		setTimeout(() => setLoaded(false), 500)
		await dispatch(searchData(payload))
		setTimeout(() => setLoaded(true), 500)
	}

    const goBack = () => {
        navigate(-1)
    }

	return (
		<div className="block block-search">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-search-heading">
					<h1>Przeszukaj duxhub</h1>
					<h2>Wpisz to czego szukasz lub wybierz z listy</h2>
				</div>
				<SearchFields
					cities={cities}
					areas={areas}
					specializations={specializations}
					studies={studies}
					workplaces={workplaces}
					activityTypes={activityTypes}
					organizations={organizations}
					onSearch={onSearch}
				/>
				<SearchResults results={results} loaded={loaded} />
				<div className="block-search-btn">
					<button className="btn btn-thirdy">ZAMKNIJ WYSZUKIWARKĘ</button>
				</div>
			</div>
		</div>
	)
}

export default SearchBlock
