import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSomeUsers, searchUsers } from '@/actions/user'
import { saveMembers } from '@/actions/organization'
import arrow from '@/assets/images/search-arrow-down.svg'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import memberAdd from '@/assets/images/member-add.svg'
import memberAdded from '@/assets/images/member-added.svg'
import memberRemove from '@/assets/images/member-remove.svg'
import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

const MemberItem = ({ member, side, onAddMember, onRemoveMember, isMemberAdded }) => {
	const [image, setImage] = useState(null)
	const [data, setData] = useState({ user_id: null, firstname: '', lastname: '' })

	useEffect(() => {
		if (member) {
			const { id: user_id, profile } = member
			const { avatar_image, firstname, lastname } = profile
			setImage(getImage(avatar_image))
			setData({ user_id, firstname, lastname })
		}
	}, [member])

	const getImage = (avatar_image) => {
		if (avatar_image) {
			return `${STORAGE_URL}/${avatar_image}` 
		}
		return userDefault
	}

	const handleAddClick = (e) => {
		e.stopPropagation()
		onAddMember(member)
	}

	const handleRemoveClick = (e) => {
		e.stopPropagation()
		onRemoveMember(member)
	}

	return (
		<div className="block-chat-user-item block-editMembers_item">
			<div>
				<div className="block-chat-user-item_photo">
					<span>{image && <img src={image} width="" height="" alt="" />}</span>
				</div>
				<p>{data.firstname} {data.lastname}</p>
			</div>
			{side === 'users' && isMemberAdded && (
				<div className="block-editMembers_item-action">					
					<img src={memberAdded} alt="Added" />
				</div>
			)}
			{side === 'users' && !isMemberAdded && (
				<div className="block-editMembers_item-action" onClick={handleAddClick}>
					<img src={memberAdd} alt="Add" />
				</div>
			)}
			{side === 'organization' && (
				<div className="block-editMembers_item-action" onClick={handleRemoveClick} >
					<img src={memberRemove} alt="Remove" />
				</div>
			)}
		</div>
	)
}

const BlockEditMembers = ({ organization, onSave = () => {} }) => {
	const dispatch = useDispatch()

	const [organizationMembers, setOrganizationMembers] = useState([])
	const [users, setUsers] = useState([])
	const [searchOrganizationMember, setSearchOrganizationMember] = useState('')
	const [searchUser, setSearchUser] = useState('')

	const foundUsers = useSelector(state => state.user.users)

	useEffect(() => {
		if (organization) {
			if (organization.members.length) {
				setOrganizationMembers(organization.members.sort(sortMembers))
				setUsers(organization.members.sort(sortMembers))
			} else {
				dispatch(getSomeUsers())
			}
		}
	}, [organization])

	useEffect(() => {
		if (foundUsers) {
			setUsers(foundUsers)
		}
	}, [foundUsers])

	const sortMembers = (a, b) => {
		const fullNameA = `${a.profile.firstname} ${a.profile.lastname}`
		const fullNameB = `${b.profile.firstname} ${b.profile.lastname}`
		if (fullNameA === fullNameB) return 0
		return fullNameA < fullNameB ? -1 : 1
	}

	useEffect(() => {
		if (searchOrganizationMember) {
			setOrganizationMembers(
				prevOrganizationMembers => prevOrganizationMembers.filter(member => {
					const fullName = `${member.profile.firstname} ${member.profile.lastname}`
					return fullName.toLowerCase().includes(searchOrganizationMember.toLowerCase())
				}).sort(sortMembers)
			)
		} else if (organization) {
			setOrganizationMembers(organization.members.sort(sortMembers))
		}
	}, [searchOrganizationMember, organization])

	useEffect(() => {
		if (searchUser) {
			dispatch(searchUsers({ search: searchUser }))
		} else if (organization) {
			if (organization.members.length) {
				setUsers(organization.members.sort(sortMembers))
			} else {
				dispatch(getSomeUsers())
			}
		}
	}, [searchUser, organization])

	const handleAddMember = (member) => {
		if (isMemberAdded(member)) {
	        return
	    }
		setOrganizationMembers(prevMembers => [...prevMembers, member].sort(sortMembers))
	}

	const handleRemoveMember = (member) => {
		setOrganizationMembers(prevMembers =>
			prevMembers.filter((m) => m.id !== member.id).sort(sortMembers)
		)
	}

	const isMemberAdded = (member) => {
		return organizationMembers.some(organizationMember => organizationMember.id === member.id)
	}

	const onSubmit = async () => {
		await dispatch(saveMembers({
			members: organizationMembers.map(member => member.id),
			organization_id: organization.id
		}))
		onSave()
	}

	return (
		<div className="block block-editMembers">
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>Zespół</h2>
				</div>
				<div className="block-editMembers_body">
					<div className="block-editMembers_side block-editMembers_side-left">
						<div className="block-editMembers_side-title">Znajdź i dodaj osobę</div>
						<div className="block-editMembers_side-body">
							<div className="block-editMembers_side-left-description">Znajdź i dodaj osobę do zespołu naciskając “+” lub przeciągając do rubryki Twój zespół</div>
							{/*<div>*/}
								<span className="form-field-arrow">
									<input
										type="text"
										name="partner"
										value={searchUser}
										onChange={(e) => setSearchUser(e.target.value)}
									/>
									<span className="button">
										<img src={arrow} alt="" />
									</span>
								</span>
							{/*</div>*/}
							<div className="block-editMembers_list">
								{users.map((member, index) => 
									<MemberItem
										key={index}
										member={member}
										side="users"
										onAddMember={handleAddMember}
										isMemberAdded={isMemberAdded(member)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="block-editMembers_side block-editMembers_side-right">
						<div className="block-editMembers_side-title">Twój zespół</div>
						<div className="block-editMembers_side-body">
							{/*<div>*/}
								<span className="form-field-arrow">
									<input
										type="text"
										name="partner"
										value={searchOrganizationMember}
										onChange={(e) => setSearchOrganizationMember(e.target.value)}
									/>
									<span className="button">
										<img src={arrow} alt="" />
									</span>
								</span>
							{/*</div>*/}
							<div className="block-editMembers_list">
								{organizationMembers.map((member, index) => 
									<MemberItem
										key={index}
										member={member}
										side="organization"
										onRemoveMember={handleRemoveMember}
									/>
								)}
							</div>
						</div>						
					</div>					
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</div>
	)
}

export default BlockEditMembers
