import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDevelopmentAreas, getSpecializations } from '@/actions/content'
import { uploadImage, saveProfile } from '@/actions/user'
import { getFullName, parseFullName } from '@/utils/user.utils'
import { checkImageType, ERROR_NOT_ALLOWED_IMAGE } from '@/utils/upload.utils'
import Select from 'react-select'
import { saveError } from '@/utils/notify.utils'
import { STORAGE_URL } from '@/constants/api'
import ImageLoader from '@/components/Common/Loader/ImageLoader'
import { workPlaces } from '@/constants/user'

import lamp from '@/assets/images/lamp.svg'
import timer from '@/assets/images/timer.svg'
import darts from '@/assets/images/darts.svg'
import shop from '@/assets/images/shop.svg'
import law from '@/assets/images/law.svg'

import {
	Instagram,
	Facebook,
	Youtube,
	Tiktok,
	Linkedin
} from '@/components/Common/Social/SocialIcons'

import ConfirmButton from './ConfirmButton'

import './styles.css'

const UploadBackgroundImage = ({ backgroundImage }) => {
	const dispatch = useDispatch()
    const imageUploadInputRef = useRef()

    const [currentImage, setCurrentImage] = useState(null)
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
    	if (backgroundImage) {
    		setCurrentImage(backgroundImage)
    	}
    }, [backgroundImage])

    const handleImageUploadClick = () => {
        imageUploadInputRef.current.click()
    }

    const handleImageUpload = async (e) => {
        const image = e.target.files[0]
        if (checkImageType(image.type)) {
    		setLoaded(false)
	        const formData = new FormData()
	        formData.append('type', 'background')
	        formData.append('image', image)
            const imageData = await dispatch(uploadImage(formData))
    		setCurrentImage(imageData.image)
    		setTimeout(() => setLoaded(true), 500)
        } else {
	        saveError(ERROR_NOT_ALLOWED_IMAGE)
	    }
    }

	return (
		<div
			className="block-wallpaper block-editProfile_wallpaper"
			style={{
				background: currentImage 
						  ? `url(${STORAGE_URL}/${currentImage}) center/cover no-repeat` 
						  : 'linear-gradient(86.54deg, #63B2E3 -1.21%, #5C8CC9 99.91%)'
			}}
		>
			<ImageLoader loaded={loaded} />
			<a className="btn btn-outline-secondary" onClick={handleImageUploadClick}>DODAJ ZDJĘCIE</a>
            <input
                type="file"
                ref={imageUploadInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
		</div>
	)
}

const UploadAvatarImage = ({ avatarImage }) => {
	const dispatch = useDispatch()
    const imageUploadInputRef = useRef()

    const [currentImage, setCurrentImage] = useState(null)
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
    	if (avatarImage) {
    		setCurrentImage(avatarImage)
    	}
    }, [avatarImage])

    const handleImageUploadClick = () => {
        imageUploadInputRef.current.click()
    }

    const handleImageUpload = async (e) => {
        const image = e.target.files[0]
        if (checkImageType(image.type)) {
    		setLoaded(false)
	        const formData = new FormData()
	        formData.append('type', 'avatar')
	        formData.append('image', image)
            const imageData = await dispatch(uploadImage(formData))
    		setCurrentImage(imageData.image)
    		setTimeout(() => setLoaded(true), 500)
        } else {
	        saveError(ERROR_NOT_ALLOWED_IMAGE)
	    }
    }

	return (
		<div className="block-editProfile_head-photo" style={{ position: 'relative' }}>
			<ImageLoader loaded={loaded} />
			<span style={{ background: `url(${STORAGE_URL}/${currentImage}) white center/contain no-repeat` }}>
				<a className="btn btn-outline-secondary" onClick={handleImageUploadClick}>DODAJ ZDJĘCIE AWATAR</a>
			</span>
            <input
                type="file"
                ref={imageUploadInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
		</div>
	)
}

const HeadFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<div className="block-editProfile_head-fields">
			<div className="form-fields">
			{params.map((param, index) => {
				const { name, label, value, size } = param
				return (
					<div className={`form-field form-field-${size}`} key={index}>
						<input
							type="text"
							name={name}
							placeholder={label}
							value={value}
							onChange={handleInputChange}
						/>
					</div>
				)
			})}
			</div>
		</div>
	)
}

const WorkFields = ({ params, onInputChange, onSelectChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

    const handleSelectChange = (name, multiple, selected) => {
		onSelectChange({ name, multiple, selected })
    }

	return (
		<div className="block-editProfile_listIcons">
			<div className="form-fields">
				{params.map((param, index) => {
					const { name, icon, label, field, value, options, multiple } = param
					return (
						<div className="form-field" key={index}>
							<div className="block-editProfile_listIcons-item">
								<span className="block-editProfile_listIcons-item_label">
									<span className="block-editProfile_listIcons-item_label-img">
										<img src={icon} width="" height="" alt="" />
									</span>
									{label}
								</span>
								<span className="block-editProfile_listIcons-item_value">
									{field === 'select' && 
										<Select
											className="search-select"
											id={name}
											name={name}
											placeholder="wybierz"
											value={value}
											options={options}
											isMulti={multiple}
											onChange={
												(selected) => handleSelectChange(name, multiple, selected)
											}
										/>
									}
									{field === 'input' && 
										<input
											type="text"
											name={name}
											placeholder="wpisz"
											value={value}
											onChange={handleInputChange}
										/>
									}
									{field === 'textarea' && 
										<textarea
											name={name}
											placeholder="wpisz"
											value={value}
											onChange={handleInputChange}
										></textarea>
									}
								</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>

	)
}

const SocialFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<div className="block-editProfile_socIcons">
			<div className="form-fields">
				{params.map((param, index) => {
					const { name, icon, value } = param
					return (

						<div className="form-field" key={index}>
							<div className="block-editProfile_socIcons-item">
								<span className="block-editProfile_socIcons-item_label">{icon}</span>
								<span className="block-editProfile_socIcons-item_value">
									<input
										type="text"
										name={name}
										placeholder="wklej adres"
										value={value}
										onChange={handleInputChange}
									/>
								</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const BlockEditUser = ({ user, onSave }) => {
	const dispatch = useDispatch()

	const areas = useSelector(state => state.content.areas)
	const specializations = useSelector(state => state.content.specializations)

	useEffect(() => {
		dispatch(getDevelopmentAreas())
		dispatch(getSpecializations())
	}, [dispatch])

	const [images, setImages] = useState({
		background_image: null,
		avatar_image: null,
	})

	const [data, setData] = useState({
		fullname: '',
		position: '',
		area_id: null,
		experience: '',
		specialization_ids: [],
		workplace: '',
		studies: '',
	})

	const [socials, setSocials] = useState({})

    const [errors, setErrors] = useState({
        fullname: false,
        position: false,
    })

	useEffect(() => {
		if (user) {
			const {
				background_image,
				avatar_image,
				position,
				area_id,
				experience,
				workplace,
				studies,
			} = user.profile

			setImages({
				background_image,
				avatar_image,
			})

			setData({
				fullname: getFullName(user),
				position: position || '',
				area_id: area_id || null,
				experience: experience || '',
				specialization_ids: user.specializations.map(item => item.id),
				workplace: workplace || '',
				studies: studies || '',
			})

			setSocials(user.socials.reduce((acc, item) => {
				acc[item.platform] = item.url
				return acc
			}, {}))
		}
	}, [user])

	const headParams = [
		{ name: 'fullname', label: 'Imię i Nazwisko', value: data.fullname, error: errors.fullname, size: 'xl' },
		{ name: 'position', label: 'Pozycja/opis', value: data.position, error: errors.position, size: 'md' },
	]

	const workParams = [
		{
			name: 'area_id',
			icon: lamp,
			label: 'Obszar rozwoju',
			field: 'select',
			value: areas.map(item => ({ label: item.name, value: item.id }))
						.find(item => item.value === data.area_id),
			options: areas.map(item => ({ label: item.name, value: item.id })),
			visible: true
		},
		{ name: 'experience', icon: timer, label: 'Lata doświdczenia', field: 'input', value: data.experience, visible: true },
		{
			name: 'specialization_ids',
			icon: darts,
			label: 'Specjalizacja',
			field: 'select',
			value: specializations.map(item => ({ label: item.name, value: item.id }))
								  .filter(item => data.specialization_ids.includes(item.value)),
			options: specializations.map(item => ({ label: item.name, value: item.id })),
			multiple: true,
			visible: true
		},
		{
			name: 'workplace',
			icon: shop,
			label: user ? workPlaces[user.account_type] : 'Miejsce pracy',
			field: 'input',
			value: data.workplace,
			visible: user ? user.account_type <= 2 : false
		},
		{ name: 'studies', icon: law, label: 'Uczelnia', field: 'input', value: data.studies, visible: true },
	]

	const socialParams = [
		{ name: 'instagram', icon: <Instagram />, value: socials.instagram || '' },
		{ name: 'facebook', icon: <Facebook />, value: socials.facebook || '' },
		{ name: 'youtube', icon: <Youtube /> , value: socials.youtube || ''},
		{ name: 'tiktok', icon: <Tiktok />, value: socials.tiktok || '' },
		{ name: 'linkedin', icon: <Linkedin />, value: socials.linkedin || '' },
	]

	const onInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
        // setErrors({ ...errors, [name]: false })
	}

	const onSelectChange = ({ name, multiple, selected }) => {
		if (multiple) {
			setData({ ...data, [name]: selected.map(item => item.value) })
		} else {
			setData({ ...data, [name]: selected.value })
		}
	}

	const onSocialChange = ({ name, value }) => {
		setSocials({ ...socials, [name]: value })
        // setErrors({ ...errors, [name]: false })
	}

	const onSubmit = async () => {
		const names = parseFullName(data.fullname)
		if (names) {
			const payload = { ...data, ...names, socials }
			console.log('payload', payload)
			await dispatch(saveProfile(payload))
			onSave()
		}
	}

	return (
		<form className="block block-editProfile">
			<UploadBackgroundImage backgroundImage={images.background_image} />
			<div className="block-editProfile_head">
				<UploadAvatarImage avatarImage={images.avatar_image} />
				<HeadFields params={headParams} onInputChange={onInputChange} />
			</div>
			<WorkFields params={workParams} onInputChange={onInputChange} onSelectChange={onSelectChange} />
			<SocialFields params={socialParams} onInputChange={onSocialChange} />
			<ConfirmButton onSubmit={onSubmit} />
		</form>
	)
}

export default BlockEditUser
