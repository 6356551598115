import { useState, useEffect } from 'react'
import ChartLine from './Structure/ChartLine'

const BudgetItem = ({ item }) => {
    const { title, date_from, date_to, total_amount: total, categories } = item
    return (
		<div className="block-budget_item">
            <div className="block-budget_item-title">{title}</div>
            <div className="block-budget_item-period">za okres od {date_from} do {date_to}</div>
            <div className="block-budget_item-sum">{total}</div>
            <div className="block-budget_charts">
                <div className="block-budget_charts-line">
                    {categories.map((category, index) => (
                        <ChartLine category={category} index={index} key={index} />
                    ))}
                </div>
                <div className="block-budget_charts-items">
                    {categories.map((category, index) => {
                        const { category_name: name, percentage: p } = category
                        return (
                            <div className="block-budget_charts-item" key={index}>
                                <div className="block-budget_charts-item_line">
                                    <ChartLine category={category} index={index} />
                                </div>
                                <p className="block-budget_charts-item_info"><span>{p}%</span>{name}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const BlockBudget = ({ organization }) => {
    const [structure, setStructure] = useState([])
	const [data, setData] = useState({
        budget: 0,
        income: 0,
        expense: 0,
        saldo: 0,
        balance: 0
    })

	useEffect(() => {
		if (organization) {
            setStructure(organization.budget_structure)
		}
	}, [organization])

    useEffect(() => {
        if (structure.length) {
            const income = Number(structure.find(item => item.code === 'income').total_amount) || 0
            const expense = Number(structure.find(item => item.code === 'expense').total_amount) || 0
            setData({ ...data, ...{ income, expense, budget: income - expense } })
        }
    }, [structure])

	return (
		<div className="block-budget block-manage-budget">
			<div className="block-budget_wrap">
				<div className="block-budget_left">
					<div className="block-heading">
						<h2>Budżet 2024</h2>
					</div>
					<p className="block-budget_total-summ">
						całkowity budżet
						<span>{new Intl.NumberFormat('pl-PL').format(data.budget)}</span>
					</p>
					<ul className="block-budget_summ">
						<li>planowane wydatki <span>{data.expense}</span></li>
						<li>saldo <span>{data.saldo}</span></li>
						<li>planowane przychody <span>{data.income}</span></li>
						<li>bilans <span>{data.balance}</span></li>
					</ul>
				</div>
				<div className="block-budget_right">
					<div className="block-heading">
						<h2>Struktura budżetu 2024</h2>
					</div>
                    <div className="block-budget_items">
                        {structure.map((item, index) => {
                            return <BudgetItem item={item} key={index} />
                        })}
                    </div>
				</div>
			</div>
		</div>
    )
}

export default BlockBudget
