import OrganizationService from '@/services/organization.service'
import { saveSuccess, saveError } from '@/utils/notify.utils'

export const GET_VIEW_ORGANIZATION_REQUEST = 'GET_VIEW_ORGANIZATION_REQUEST'
export const GET_VIEW_ORGANIZATION_SUCCESS = 'GET_VIEW_ORGANIZATION_SUCCESS'
export const GET_VIEW_ORGANIZATION_FAILURE = 'GET_VIEW_ORGANIZATION_FAILURE'
export const GET_FREE_ORGANIZATIONS_SUCCESS = 'GET_FREE_ORGANIZATIONS_SUCCESS'
export const GET_FREE_ORGANIZATIONS_FAILURE = 'GET_FREE_ORGANIZATIONS_FAILURE'
export const GET_MY_ORGANIZATIONS_SUCCESS = 'GET_MY_ORGANIZATIONS_SUCCESS'
export const GET_MY_ORGANIZATIONS_FAILURE = 'GET_MY_ORGANIZATIONS_FAILURE'
export const GET_MY_ORGANIZATION_REQUEST = 'GET_MY_ORGANIZATION_REQUEST'
export const GET_MY_ORGANIZATION_SUCCESS = 'GET_MY_ORGANIZATION_SUCCESS'
export const GET_MY_ORGANIZATION_FAILURE = 'GET_MY_ORGANIZATION_FAILURE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'
export const SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS'
export const SAVE_ORGANIZATION_FAILURE = 'SAVE_ORGANIZATION_FAILURE'
export const GET_ORGANIZATION_PERMISSIONS_SUCCESS = 'GET_ORGANIZATION_PERMISSIONS_SUCCESS'
export const GET_ORGANIZATION_PERMISSIONS_FAILURE = 'GET_ORGANIZATION_PERMISSIONS_FAILURE'
export const SAVE_ORGANIZATION_PERMISSIONS_SUCCESS = 'SAVE_ORGANIZATION_PERMISSIONS_SUCCESS'
export const SAVE_ORGANIZATION_PERMISSIONS_FAILURE = 'SAVE_ORGANIZATION_PERMISSIONS_FAILURE'

export const getViewOrganizationRequest = () => ({ type: GET_VIEW_ORGANIZATION_REQUEST })
export const getViewOrganizationSuccess = (data) => ({ type: GET_VIEW_ORGANIZATION_SUCCESS, payload: data })
export const getViewOrganizationFailure = (error) => ({ type: GET_VIEW_ORGANIZATION_FAILURE, payload: error })
export const getFreeOrganizationsSuccess = (data) => ({ type: GET_FREE_ORGANIZATIONS_SUCCESS, payload: data })
export const getFreeOrganizationsFailure = (error) => ({ type: GET_FREE_ORGANIZATIONS_FAILURE, payload: error })
export const getMyOrganizationsSuccess = (data) => ({ type: GET_MY_ORGANIZATIONS_SUCCESS, payload: data })
export const getMyOrganizationsFailure = (error) => ({ type: GET_MY_ORGANIZATIONS_FAILURE, payload: error })
export const getMyOrganizationRequest = () => ({ type: GET_MY_ORGANIZATION_REQUEST })
export const getMyOrganizationSuccess = (data) => ({ type: GET_MY_ORGANIZATION_SUCCESS, payload: data })
export const getMyOrganizationFailure = (error) => ({ type: GET_MY_ORGANIZATION_FAILURE, payload: error })
export const uploadImageSuccess = () => ({ type: UPLOAD_IMAGE_SUCCESS })
export const uploadImageFailure = (error) => ({ type: UPLOAD_IMAGE_FAILURE, payload: error })
export const saveOrganizationSuccess = () => ({ type: SAVE_ORGANIZATION_SUCCESS })
export const saveOrganizationFailure = (error) => ({ type: SAVE_ORGANIZATION_FAILURE, payload: error })
export const getPermissionsSuccess = (data) => ({ type: GET_ORGANIZATION_PERMISSIONS_SUCCESS, payload: data })
export const getPermissionsFailure = (error) => ({ type: GET_ORGANIZATION_PERMISSIONS_FAILURE, payload: error })
export const savePermissionsSuccess = () => ({ type: SAVE_ORGANIZATION_PERMISSIONS_SUCCESS })
export const savePermissionsFailure = (error) => ({ type: SAVE_ORGANIZATION_PERMISSIONS_FAILURE, payload: error })

export const getOrganizationById = (id) => async (dispatch) => {
  try {
    dispatch(getViewOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getOrganizationById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewOrganizationSuccess(result.organization))
    } else {
      dispatch(getViewOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewOrganizationFailure(e.toString()))
  }
}

export const getOrganizationBySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getViewOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getOrganizationBySlug(slug, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewOrganizationSuccess(result.organization))
    } else {
      dispatch(getViewOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewOrganizationFailure(e.toString()))
  }
}

export const getFreeOrganizations = (search = null) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getFreeOrganizations(search, token)
    console.log(result)
    if (result.success) {
      dispatch(getFreeOrganizationsSuccess(result.organizations))
      return result.organizations
    } else {
      dispatch(getFreeOrganizationsFailure(result.message))
      return null
    }
  } catch (e) {
    dispatch(getFreeOrganizationsFailure(e.toString()))
    return null
  }
}

export const getMyOrganizations = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizations(token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationsSuccess(result.organizations))
      return result.organizations
    } else {
      dispatch(getMyOrganizationsFailure(result.message))
      return null
    }
  } catch (e) {
    dispatch(getMyOrganizationsFailure(e.toString()))
    return null
  }
}

export const getMyOrganizationById = (id) => async (dispatch) => {
  try {
    dispatch(getMyOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizationById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationSuccess(result.organization))
    } else {
      dispatch(getMyOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOrganizationFailure(e.toString()))
  }
}

export const getMyOrganizationBySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getMyOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizationBySlug(slug, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationSuccess(result.organization))
    } else {
      dispatch(getMyOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOrganizationFailure(e.toString()))
  }
}

export const createOrganization = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.createOrganization(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      saveSuccess()      
      return { organizationId: result.organization_id, organizationSlug: result.slug }
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
      return null
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
    return null
  }
}

export const uploadImage = (payload, organization_id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.uploadImage(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(uploadImageSuccess())
      dispatch(getMyOrganizationById(organization_id))
      saveSuccess()
      return result.data
    } else {
      dispatch(uploadImageFailure(result.error))
      saveError(result.error)
      return null
    }
  } catch (e) {
    dispatch(uploadImageFailure(e.toString()))
    saveError(e.toString())
    return null
  }
}

export const saveInfo = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.saveInfo(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      dispatch(getMyOrganizationById(payload.organization_id))
      saveSuccess()
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveAbout = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.saveAbout(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      dispatch(getMyOrganizationById(payload.organization_id))
      saveSuccess()
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveMembers = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.saveMembers(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      dispatch(getMyOrganizationById(payload.organization_id))
      saveSuccess()
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
  }
}

export const deleteMember = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.deleteMember(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      dispatch(getMyOrganizationById(payload.organization_id))
      saveSuccess()
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
  }
}

export const requestMembership = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.requestMembership(payload, token)
    console.log(result)
    if (result.success) {
      saveSuccess()
      return true
    } else {
      saveError(result.error)
      return false
    }
  } catch (e) {
    saveError(e.toString())
    return false
  }
}

export const getPermissions = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getPermissions(token)
    console.log(result)
    if (result.success) {
      dispatch(getPermissionsSuccess(result.permissions))
    } else {
      dispatch(getPermissionsFailure(result.error))
    }
  } catch (e) {
    dispatch(getPermissionsFailure(e.toString()))
  }
}

export const savePermissions = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.savePermissions(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(savePermissionsSuccess())
      saveSuccess()
    } else {
      dispatch(savePermissionsFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(savePermissionsFailure(e.toString()))
    saveError(e.toString())
  }
}
