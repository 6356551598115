import {
  GET_VIEW_ORGANIZATION_REQUEST,
  GET_VIEW_ORGANIZATION_SUCCESS,
  GET_VIEW_ORGANIZATION_FAILURE,
  GET_FREE_ORGANIZATIONS_SUCCESS,
  GET_FREE_ORGANIZATIONS_FAILURE,
  GET_MY_ORGANIZATIONS_SUCCESS,
  GET_MY_ORGANIZATIONS_FAILURE,
  GET_MY_ORGANIZATION_REQUEST,
  GET_MY_ORGANIZATION_SUCCESS,
  GET_MY_ORGANIZATION_FAILURE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  SAVE_ORGANIZATION_SUCCESS,
  SAVE_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_PERMISSIONS_SUCCESS,
  GET_ORGANIZATION_PERMISSIONS_FAILURE,
  SAVE_ORGANIZATION_PERMISSIONS_SUCCESS,
  SAVE_ORGANIZATION_PERMISSIONS_FAILURE,
} from '../actions/organization'

const initialState = {
  viewOrganizationLoading: true,
  viewOrganization: null,
  freeOrganizations: [],
  myOrganizations: [],
  myOrganizationLoading: true,
  myOrganization: null,
  viewProjectLoading: true,
  viewProject: null,
  // myProjectLoading: true,
  // myProject: null,
  permissions: [],
  error: null,
}

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIEW_ORGANIZATION_REQUEST:
      return {
        ...state,
        viewOrganizationLoading: true
      }
    case GET_VIEW_ORGANIZATION_SUCCESS:
      return {
        ...state,
        viewOrganizationLoading: false,
        viewOrganization: action.payload,
        error: null
      }
    case GET_VIEW_ORGANIZATION_FAILURE:
      return {
        ...state,
        viewOrganizationLoading: false,
        viewOrganization: null,
        error: action.payload
      }
    case GET_FREE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        freeOrganizations: action.payload,
        error: null
      }
    case GET_FREE_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        freeOrganizations: [],
        error: action.payload
      }
    case GET_MY_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        myOrganizations: action.payload,
        error: null
      }
    case GET_MY_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        myOrganizations: [],
        error: action.payload
      }
    case GET_MY_ORGANIZATION_REQUEST:
      return {
        ...state,
        myOrganizationLoading: true
      }
    case GET_MY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        myOrganizationLoading: false,
        myOrganization: action.payload,
        error: null
      }
    case GET_MY_ORGANIZATION_FAILURE:
      return {
        ...state,
        myOrganizationLoading: false,
        myOrganization: null,
        error: action.payload
      }
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        error: null
      } 
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case SAVE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        error: null
      }
    case SAVE_ORGANIZATION_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case GET_ORGANIZATION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        error: null
      }
    case GET_ORGANIZATION_PERMISSIONS_FAILURE:
      return {
        ...state,
        permissions: [],
        error: action.payload
      }
    case SAVE_ORGANIZATION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        error: null
      }
    case SAVE_ORGANIZATION_PERMISSIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}

export default organizationReducer
