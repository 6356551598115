import api from './api'

class AuthService
{
	async getAccountTypes() {
		try {
			const response = await api.get('/auth/account-types')
			return response.data
		} catch (e) {
			console.log('[AuthService][getAccountTypes][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async register(payload) {
		try {
			const response = await api.post('/auth/register', payload)
			return response.data
		} catch (e) {
			console.log('[AuthService][register][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async verifyEmail(payload) {
		try {
			const response = await api.post('/auth/verify-email', payload)
			return response.data
		} catch (e) {
			console.log('[AuthService][verifyEmail][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async login(payload) {
		try {
			const response = await api.post('/auth/login', payload)
			return response.data
		} catch (e) {
			console.log('[AuthService][login][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async recoverPassword(payload) {
		try {
			const response = await api.post('/auth/password-recover', payload) 
			return response.data 
		} catch (e) {
			console.log('[AuthService][recoverPassword][error]', e) 
			return { success: false, message: e.toString() } 
		}
	}

	async checkRecoverToken(payload) {
		try {
			const response = await api.post('/auth/check-recover', payload) 
			return response.data 
		} catch (e) {
			console.log('[AuthService][checkRecoverToken][error]', e) 
			return { success: false, message: e.toString() } 
		}
	}

	async changePassword(payload) {
		try {
			const response = await api.post('/auth/password-change', payload) 
			return response.data 
		} catch (e) {
			console.log('[AuthService][changePassword][error]', e) 
			return { success: false, message: e.toString() } 
		}
	}

	async getUser(token) {
		try {
			const response = await api.get('/auth/user', {
	            headers: {
	            	Authorization: `Bearer ${token}`,
	            	'Cache-Control': 'no-cache'
	           	}
	        })
			return response.data
		} catch (e) {
			// console.log('[AuthService][getUser][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveSettings(payload, token) {
		try {
			const response = await api.post('/auth/settings/save', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[AuthService][saveSettings][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new AuthService()
