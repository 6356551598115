import {
    GET_NOTIFICATION_TYPES_SUCCESS,
    GET_NOTIFICATION_TYPES_FAILURE,
    GET_NOTIFICATION_SETTINGS_SUCCESS,
    GET_NOTIFICATION_SETTINGS_FAILURE,
    SAVE_NOTIFICATION_SETTINGS_SUCCESS,
    SAVE_NOTIFICATION_SETTINGS_FAILURE,
    GET_UNREAD_NOTIFICATIONS_SUCCESS,
    GET_UNREAD_NOTIFICATIONS_FAILURE,
    GET_DATED_NOTIFICATIONS_SUCCESS,
    GET_DATED_NOTIFICATIONS_FAILURE,
    SET_UNREAD_NOTIFICATIONS_COUNT,
  } from '../actions/notification'
  
  const initialState = {
    types: [],
    settings: [],
    unreadNotifications: [],
    datedNotifications: [],
    unreadCount: 0,
    error: null,
  }
  
  const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NOTIFICATION_TYPES_SUCCESS:
        return {
          ...state,
          types: action.payload,
          error: null
        } 
      case GET_NOTIFICATION_TYPES_FAILURE:
        return {
          ...state,
          types: [],
          error: action.payload
        }
      case GET_NOTIFICATION_SETTINGS_SUCCESS:
        return {
          ...state,
          settings: action.payload,
          error: null
        } 
      case GET_NOTIFICATION_SETTINGS_FAILURE:
        return {
          ...state,
          settings: [],
          error: action.payload
        }
      case SAVE_NOTIFICATION_SETTINGS_SUCCESS:
        return {
          ...state,
          error: null
        } 
      case SAVE_NOTIFICATION_SETTINGS_FAILURE:
        return {
          ...state,
          error: action.payload
        }
      case GET_UNREAD_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          unreadNotifications: action.payload,
          unreadCount: action.payload.length,
          error: null
        } 
      case GET_UNREAD_NOTIFICATIONS_FAILURE:
        return {
          ...state,
          unreadNotifications: [],
          unreadCount: 0,
          error: action.payload
        }
      case GET_DATED_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          datedNotifications: action.payload,
          error: null
        } 
      case GET_DATED_NOTIFICATIONS_FAILURE:
        return {
          ...state,
          datedNotifications: [],
          error: action.payload
        }
      case SET_UNREAD_NOTIFICATIONS_COUNT:
        return {
          ...state,
          unreadCount: action.payload
        }
      default:
        return state
    }
  }
  
  export default notificationReducer
  