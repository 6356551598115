import { useState, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { saveApplication } from '@/actions/manage'
import { universities } from '@/constants/manage'
import applicationSchema from '@/validations/application.schema'
import { formatDate } from '@/utils/datetime.utils'
import plus from '@/assets/images/plus-black.svg'
import Select from 'react-select'
import DateEditor from '@/components/Common/Edit/DateEditor'
import Buttons from './Buttons'

const defaultPerson = { firstname: '', lastname: '' }

const PersonFields = ({ label, persons, onChange, addPerson, errors }) => {
    return (
        <div className="manage-dialog-form-field manage-dialog-form-persons">
            <label>{label}</label>
            <div className="manage-dialog-form-field-grid">
                {persons.map((person, index) => {
                    const { firstname, lastname } = person
                    return (
                        <Fragment key={index}>
                            <div className={`manage-dialog-form-field sm ${errors[index]?.firstname ? ' form-field-error' : ''}`}>
                                <input
                                    id={`firstname-${index}`}
                                    name={`firstname-${index}`}
                                    type="text"
                                    placeholder="imię"
                                    value={firstname}
                                    onChange={
                                        (e) => onChange(index, 'firstname', e.target.value)
                                    }
                                />
                                {errors[index]?.firstname && <span className="form-field-icon">!</span>}
                            </div>
                            <div className={`manage-dialog-form-field md ${errors[index]?.lastname ? ' form-field-error' : ''}`}>
                                <input
                                    id={`lastname-${index}`}
                                    name={`lastname-${index}`}
                                    type="text"
                                    placeholder="nazwisko"
                                    value={lastname}
                                    onChange={
                                        (e) => onChange(index, 'lastname', e.target.value)
                                    }
                                />
                                {errors[index]?.lastname && <span className="form-field-icon">!</span>}
                            </div>
                        </Fragment>
                    )
                })}
                <div
                    className="manage-dialog-form-field-add"
                    onClick={addPerson}
                >
                    <img src={plus} alt="" />
                    <span>dodaj kolejną osobę</span>
                </div>
            </div>
        </div>
    )
}

const ApplicationForm = ({ organization, btnText, onCancel }) => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        organization_name: '',
        university: '',
        datetime: null
    })

    useEffect(() => {
        if (organization) {
            setData({ ...data, organization_name: organization.name })
        }
    }, [organization])

    const [persons, setPersons] = useState({
        decision: [defaultPerson],
        request: [defaultPerson],
    })
    const [errors, setErrors] = useState({
        organization_name: false,
        decision: [],
        request: [],
        university: false,
        datetime: false
    })

    const [universityEnable, setUniversityEnable] = useState(false)

    const personParams = [
        { type: 'decision', label: 'Osoby decyzyjne' },
        { type: 'request', label: 'Osoby zgłaszające wniosek' },
    ]

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
		setErrors({ ...errors, [name]: false })
    }

    const handleCheckboxChange = (e) => {
    	const { checked } = e.target
        setUniversityEnable(checked)
    }

    const handleSelectChange = (name, value) => {
        setData({ ...data, [name]: value })
		setErrors({ ...errors, [name]: false })
    }

	const handleDateChange = ({ name, value }) => {
        setData({ ...data, [name]: value })
		setErrors({ ...errors, [name]: false })
	}

    const addPerson = (type) => {
        setPersons((prevPersons) => ({
            ...prevPersons,
            [type]: [...prevPersons[type], defaultPerson],
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            [type]: [...prevErrors[type], {}],
        }))
    }

    const onPersonChange = (type, index, name, value) => {
        setPersons((prevPersons) => {
            const updatedTypePersons = [...prevPersons[type]]
            updatedTypePersons[index] = {
                ...updatedTypePersons[index],
                [name]: value,
            }
            return {
                ...prevPersons,
                [type]: updatedTypePersons,
            }
        })
        setErrors((prevErrors) => {
            const updatedErrors = [...prevErrors[type]]
            updatedErrors[index] = { ...updatedErrors[index], [name]: !value }
            return { ...prevErrors, [type]: updatedErrors }
        })
    }

	const setError = (name) => {
		setErrors({ ...errors, [name]: true })
		return false
	}

	const validateData = async (formData) => {
		try {
			await applicationSchema.validate(formData, { abortEarly: false })
			return true
		} catch (errors) {
			console.error(errors.inner)
			let error = errors.inner[0]
			return setError(error.params.path)
		}
	}

    const validatePersons = () => {
        let valid = true
        const updatedErrors = { decision: [], request: [] }

        const filteredPersons = Object.keys(persons).reduce((acc, type) => {
            const validPersons = persons[type].filter((person, index) => {
                const { firstname, lastname } = person
                const isFirstnameEmpty = !firstname.trim()
                const isLastnameEmpty = !lastname.trim()

                if (isFirstnameEmpty && isLastnameEmpty) return false

                if (isFirstnameEmpty || isLastnameEmpty) {
                    updatedErrors[type][index] = {
                        firstname: isFirstnameEmpty,
                        lastname: isLastnameEmpty,
                    }
                    valid = false
                    return true
                }

                return true
            })
            acc[`${type}_persons`] = validPersons
            return acc
        }, {})

        setErrors(updatedErrors)

        return valid ? filteredPersons : false
    }

    const onSubmit = async () => {
        const formValid = await validateData(data)
        if (formValid) {
            const filteredPersons = validatePersons()
            if (filteredPersons) {
                const payload = {
                    organization_id: organization.id,
                    ...data,
                    datetime: formatDate(data.datetime),
                    ...filteredPersons
                }
                console.log(payload)
                await dispatch(saveApplication(payload))
                onCancel()
            }
        }
    }

    return (
        <div className="manage-dialog-form transaction-form">
            <div className={`manage-dialog-form-field ${errors.organization_name ? ' form-field-error' : ''}`}>
                <label htmlFor="organization_name">Nazwa organizacji</label>
                <input
                    id="organization_name"
                    name="organization_name"
                    type="text"
                    placeholder="wpisz"
                    value={data.organization_name}
                    onChange={handleInputChange}
                />
                {errors.organization_name && <span className="form-field-icon">!</span>}
            </div>
            {personParams.map((item, pi) => {
                const { type, label } = item
                return (
                    <PersonFields
                        key={pi}
                        label={label}
                        persons={persons[type]}
                        onChange={(index, name, value) => onPersonChange(type, index, name, value)}
                        addPerson={() => addPerson(type)}
                        errors={errors[type]}
                    />
                )
            })}
            <div className="manage-dialog-form-field manage-dialog-form-university">
                <label>Organizacja z Uniwersytetu</label>
                <div className="manage-dialog-form-field-grid">
                    <div className="manage-dialog-form-field fw manage-dialog-form-checkbox">
                        <input
                            type="checkbox"
                            id="universityEnable"
                            checked={universityEnable}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="universityEnable">zaznacz jeśli tak</label>
                    </div>
                    {universityEnable && <div className="manage-dialog-form-field fw block-search-field">
                        <div className="select-field">
                            <Select
                                className="search-select"
                                name="university"
                                placeholder="wybierz Uniwesytet z listy"
                                isDisabled={!universityEnable}
                                value={universities.map(
                                    item => ({ label: item, value: item })
                                ).find(
                                    item => item.value === data.university
                                )}
                                options={universities.map(
                                    item => ({ label: item, value: item })
                                )}
                                onChange={
                                    (option) => handleSelectChange('university', option.value)
                                }
                            />
                        </div>
                    </div>}
                </div>
            </div>
            <div className="manage-dialog-form-field">
                <label>Data wniosku</label>
                <DateEditor
                    wrapperClassName={errors.datetime ? 'form-field-error' : ''}
                    innerHtml={errors.datetime ? <span className="form-field-icon">!</span> : null}
                    name="datetime"
                    selected={data.datetime}
                    onChange={handleDateChange}
                />
            </div>
            <div className="application-accept">
                <div className="application-accept-text">
                    Zgoda na Ogólne Warunki Uczestnictwa - przeczytaj OWU (link) nim zaznaczysz zgodę
                </div>
                <div className="application-accept-checkbox">
                    <input type="checkbox" id="accept" />
                    <label htmlFor="accept">zaznacz jeśli zgadzasz się z OWU</label>
                </div>
            </div>
            <Buttons btnText={btnText} onCancel={onCancel} onSubmit={onSubmit}/>
        </div>
    )
}

export default ApplicationForm
