import { useState } from 'react'

const DynamicLogo = ({ src, alt = '' }) => {
    const [orientation, setOrientation] = useState(null)

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target

        if (naturalWidth > naturalHeight) {
            setOrientation('landscape')
        } else if (naturalHeight > naturalWidth) {
            setOrientation('portrait')
        } else {
            setOrientation('square')
        }
    }

    return (
        <div
            className={`image-container ${orientation}`}
            style={{
                width: orientation === 'landscape' ? '100%' : '100%',
                height: orientation === 'portrait' ? '100%' : 'auto'
            }}
        >
            <img 
                src={src} 
                alt={alt} 
                onLoad={handleImageLoad} 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            />
        </div>
    )
}

export default DynamicLogo
