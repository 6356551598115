import { useState, useEffect } from 'react'
import DateEditor from '@/components/Common/Edit/DateEditor'
import Select from 'react-select'
import { formatDate } from '@/utils/datetime.utils'

const ActivitiesFilters = ({ activityStatuses, onFilterSelected }) => {
    const [data, setData] = useState({
        date_from: null,
        date_to: null,
        status: null,
    })

    const [statuses, setStatuses] = useState([])

    useEffect(() => {
        setStatuses([
            { value: null, label: 'wszystkie' },
            ...activityStatuses.map((label, value) => ({ value, label }))
        ])
    }, [activityStatuses])

    const handleDateChange = ({ name, value }) => {
        setData({ ...data, [name]: value })
        onFilterSelected({ [name]: formatDate(value) })
    }

	const handleSelectChange = (option) => {
		const { value: status } = option
        setData({ ...data, status })
        onFilterSelected({ status })
	}

    return (
        <div className="activities-filters">
            <div className="activities-filters-item">
                <label>data od</label>
                <div>
                    <DateEditor
                        name="date_from"
                        placeholder="DD/MM/RRRR"
                        selected={data.date_from}
                        allowFuture={false}
                        onChange={handleDateChange}
                    />
                </div>
            </div>
            <div className="activities-filters-item">
                <label>data do</label>
                <div>
                    <DateEditor
                        name="date_to"
                        placeholder="DD/MM/RRRR"
                        selected={data.date_to}
                        allowFuture={false}
                        onChange={handleDateChange}
                    />
                </div>
            </div>
            <div className="activities-filters-item">
                <label>status</label>
                <div className="activities-filters-item-select block-search-field">
                    <Select
                        className="search-select"
                        name="status"
                        placeholder="status"
                        value={
                            statuses.find(opt => opt.value === data.status) 
                            || 
                            ({ value: null, label: 'wszystkie' })
                        }
                        options={statuses}
                        onChange={(option) => handleSelectChange(option)}
                    />
                </div>
            </div>

        </div>
    )
}

export default ActivitiesFilters
