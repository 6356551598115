import { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { reverseDateFormat } from '@/utils/datetime.utils'

const StageItem = ({ stage }) => {
	return (		
		<div className="block-timeline-item">
			<div className="block-timeline-item__top">
				<div className="block-timeline-item__top-inner">
					<span></span>
					{stage.date_to && <p>{reverseDateFormat(stage.date_to)}</p>}
					{!stage.date_to && <p>nieokreślona</p>}
				</div>
			</div>
			<div className="block-timeline-item__wrap">
				<p className="block-timeline-item__title">{stage.title}</p>
				<p className="block-timeline-item__date">{reverseDateFormat(stage.date_from)}</p>
				<div className="block-timeline-item__content">
					<p>{stage.description}</p>
				</div>
			</div>
		</div>
	)
}

const BlockStages = ({ project }) => {
	const [stages, setStages] = useState([])
	const [dates, setDates] = useState({ from: null, to: null })

	useEffect(() => {
		if (project) {
			setStages(project.stages)
			setDates({
				from: project.date_from || null,
				to: project.date_to || null
			})
		}
	}, [project])

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        768: {
            margin: 10,
            items: 3
        },
        992: {
            margin: 16,
            items: 3
        },
        1450: {
            margin: 28,
            items: 3
        }
    }

	if (!stages.length) {
		return null
	}

	return (
		<div className="block block-timeline">
			<div className="block-wrap">
				<div className="block-heading yellow">
					<h2>timeline projektu</h2>
				</div>
				<div className="block-timeline-period">
					<p>start projektu: {reverseDateFormat(dates.from)}</p>
					<p>koniec projektu: {reverseDateFormat(dates.to)}</p>
				</div>
				{stages.length !== 0 && (
					<div className="block-timeline-items">
						<span className="block-timeline-line"></span>
						<OwlCarousel
							className='owl-timeline owl-carousel'
							loop
							nav
							dots={false}
							center={true}
							responsive={responsive}
						>
							{stages.map((stage, index) => <StageItem stage={stage} key={index} />)}
						</OwlCarousel>
					</div>
				)}
			</div>
		</div>
	)
}

export default BlockStages
