import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ChartLine from '@/components/Manage/Structure/ChartLine'

const TabBlock = ({ id, active, item }) => {
    const { categories } = item
	return (
		<div className={`block-budget_tabs-block tab-${id} ${active ? 'active' : ''}`}>
			<div className="block-budget_charts">
                <div className="block-budget_charts-line">
                    {categories.map((category, index) => (
                        <ChartLine category={category} index={index} key={index} />
                    ))}
                </div>
                <div className="block-budget_charts-items">
                    {categories.map((category, index) => {
                        const { category_name: name, percentage: p } = category
                        return (
                            <div className="block-budget_charts-item" key={index}>
                                <div className="block-budget_charts-item_line">
                                    <ChartLine category={category} index={index} />
                                </div>
                                <p className="block-budget_charts-item_info"><span>{p}%</span>{name}</p>
                            </div>
                        )
                    })}
                </div>
			</div>
		</div>
	)
}

const BlockDashboardBudget = ({ organization }) => {
    const [structure, setStructure] = useState([])
	const [data, setData] = useState({
        budget: 0,
        income: 0,
        expense: 0,
        saldo: 0,
        balance: 0
    })
	const [openTab, setOpenTab] = useState(1)

	useEffect(() => {
		if (organization) {
            setStructure(organization.budget_structure)
		}
	}, [organization])

    useEffect(() => {
        if (structure.length) {
            const income = Number(structure.find(item => item.code === 'income').total_amount) || 0
            const expense = Number(structure.find(item => item.code === 'expense').total_amount) || 0
            setData({ ...data, ...{ income, expense, budget: income - expense } })
        }
    }, [structure])

	const tabs = [
		{ id: 1, title: 'wydatki', code: 'expense' },
		{ id: 2, title: 'przychody', code: 'income' },
	]

	return (
		<div className="block block-budget">
			<div className="block-wrap block-budget_wrap">
				<div className="block-budget_left">
					<div className="block-heading">
						<h2>Budżet 2024</h2>
					</div>
					<p className="block-budget_total-summ">
						całkowity budżet
						<span>{new Intl.NumberFormat('pl-PL').format(data.budget)}</span>
					</p>
					<ul className="block-budget_summ">
						<li>planowane wydatki <span>{data.expense}</span></li>
						<li>saldo <span>{data.saldo}</span></li>
						<li>planowane przychody <span>{data.income}</span></li>
						<li>bilans <span>{data.balance}</span></li>
					</ul>
				</div>
				<div className="block-budget_right">
					<div className="block-heading">
						<h2>Struktura budżetu 2024</h2>
					</div>
					{structure.length !== 0 && <div className="block-budget_tabs">
						<ul className="block-budget_tabs-nav">
							{tabs.map(item => {
								const { id, title } = item
								return (
									<li
										className={`${openTab === id ? 'active' : ''}`}
										key={id}
										style={{ cursor: 'pointer' }}
									>
										<a onClick={() => setOpenTab(id)}>{title}</a>
									</li>
								)
							})}
						</ul>
						<div className="block-budget_tabs-blocks">
							{tabs.map(item => {
								const { id, code } = item
								return (
									<TabBlock
										id={id}
										active={openTab === id}
										key={id}
										item={structure.find(i => i.code === code)}
									/>
								)
							})}
						</div>
					</div>}
				</div>
				{organization && organization.permissions.includes('finance') && (
					<div className="block-budget_button">
						<Link
							className="btn btn-primary"
							to={`/organization/manage/${organization.slug}`}
						>ZARZĄDZAJ FINANSAMI I ADMINISTRACJĄ</Link>
					</div>
				)}
			</div>
		</div>
	)
}

export default BlockDashboardBudget
