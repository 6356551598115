import AuthService from '@/services/auth.service'
import { saveSuccess, saveError } from '@/utils/notify.utils'

export const GET_ACCOUNT_TYPES_SUCCESS = 'GET_ACCOUNT_TYPES_SUCCESS'
export const GET_ACCOUNT_TYPES_FAILURE = 'GET_ACCOUNT_TYPES_FAILURE'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS'
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE'
export const CHECK_RECOVER_TOKEN_SUCCESS = 'CHECK_RECOVER_TOKEN_SUCCESS'
export const CHECK_RECOVER_TOKEN_FAILURE = 'CHECK_RECOVER_TOKEN_FAILURE'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export const LOGOUT = 'LOGOUT'
export const RESET_ERROR = 'RESET_ERROR'

export const getAccountTypesSuccess = (data) => ({ type: GET_ACCOUNT_TYPES_SUCCESS, payload: data })
export const getAccountTypesFailure = (error) => ({ type: GET_ACCOUNT_TYPES_FAILURE, payload: error })
export const registerSuccess = () => ({ type: REGISTER_SUCCESS })
export const registerFailure = (error) => ({ type: REGISTER_FAILURE, payload: error })
export const verifyEmailSuccess = (data) => ({ type: VERIFY_EMAIL_SUCCESS, payload: data })
export const verifyEmailFailure = (error) => ({ type: VERIFY_EMAIL_FAILURE, payload: error })
export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, payload: data })
export const loginFailure = (error) => ({ type: LOGIN_FAILURE, payload: error })
export const recoverPasswordSuccess = () => ({ type: RECOVER_PASSWORD_SUCCESS })
export const recoverPasswordFailure = (error) => ({ type: RECOVER_PASSWORD_FAILURE, payload: error })
export const checkRecoverTokenSuccess = (data) => ({ type: CHECK_RECOVER_TOKEN_SUCCESS, payload: data })
export const checkRecoverTokenFailure = (error) => ({ type: CHECK_RECOVER_TOKEN_FAILURE, payload: error })
export const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS })
export const changePasswordFailure = (error) => ({ type: CHANGE_PASSWORD_FAILURE, payload: error })
export const getUserRequest = () => ({ type: GET_USER_REQUEST })
export const getUserSuccess = (data) => ({ type: GET_USER_SUCCESS, payload: data })
export const getUserFailure = (error) => ({ type: GET_USER_FAILURE, payload: error })
export const logoutSuccess = () => ({ type: LOGOUT })
export const resetError = () => ({ type: RESET_ERROR })

export const getAccountTypes = () => async (dispatch) => {
  try {
    const result = await AuthService.getAccountTypes()
    console.log(result)
    if (result.success) {
      dispatch(getAccountTypesSuccess(result.account_types))
    } else {
      dispatch(getAccountTypesFailure(result.message))
    }
  } catch (e) {
    dispatch(getAccountTypesFailure(e.toString()))
  }
}

export const register = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.register(payload)
    console.log(result)
    if (result.success) {
      // localStorage.setItem('token', result.token)
      dispatch(registerSuccess())
    } else {
      dispatch(registerFailure(result.message))
    }
    return result.success
  } catch (e) {
    dispatch(registerFailure(e.toString()))
    return false
  }
}

export const verifyEmail = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.verifyEmail(payload)
    console.log(result)
    if (result.success) {
      localStorage.setItem('token', result.token)
      dispatch(verifyEmailSuccess(result))
    } else {
      dispatch(verifyEmailFailure(result.message))
    }
    return result.success
  } catch (e) {
    dispatch(verifyEmailFailure(e.toString()))
    return false
  }
}

export const login = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.login(payload)
    console.log(result)
    if (result.success) {
      localStorage.setItem('token', result.token)
      dispatch(loginSuccess(result))
    } else {
      dispatch(loginFailure(result.message))
    }
    return result.success
  } catch (e) {
    dispatch(loginFailure(e.toString()))
    return false
  }
}

export const recoverPassword = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.recoverPassword(payload)
    console.log(result)
    if (result.success) {
      dispatch(recoverPasswordSuccess())
    } else {
      dispatch(recoverPasswordFailure(result.message))
    }
  } catch (e) {
    dispatch(recoverPasswordFailure(e.toString()))
  }
}

export const checkRecoverToken = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.checkRecoverToken(payload)
    console.log(result)
    if (result.success) {
      dispatch(checkRecoverTokenSuccess(result.email))
    } else {
      dispatch(checkRecoverTokenFailure(result.message))
    }
  } catch (e) {
    dispatch(checkRecoverTokenFailure(e.toString()))
  }
}

export const changePassword = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.changePassword(payload)
    console.log(result)
    if (result.success) {
      dispatch(changePasswordSuccess())
    } else {
      dispatch(changePasswordFailure(result.message))
    }
    return result.success
  } catch (e) {
    dispatch(changePasswordFailure(e.toString()))
    return false
  }
}

export const getUser = () => async (dispatch) => {
  try {
    dispatch(getUserRequest())
    const token = localStorage.getItem('token')
    if (!token) {
      dispatch(getUserFailure(null))
      return
    }
    const result = await AuthService.getUser(token)
    console.log(result)
    if (result.success) {
      dispatch(getUserSuccess(result.user))
    } else {
      dispatch(getUserFailure(result.message))
    }
  } catch (e) {
    dispatch(getUserFailure(e.toString()))
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('token')
  dispatch(logoutSuccess())
}

export const saveSettings = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await AuthService.saveSettings(payload, token)
    console.log(result)
    if (result.success) {
      saveSuccess()
    } else {
      saveError(result.error)
    }
  } catch (e) {
    saveError(e.toString())
  }
}
