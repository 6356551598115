import { useState, useEffect } from 'react'

const Tab = ({ item, count, active, onChoose }) => {
    return (
        <div
            className={`notifications-tabs-item ${active ? 'active' : ''}`}
            onClick={() => onChoose(item.code)}
        >
            <span>{item.title}</span>
            {count > 0 && <div className="notifications-tabs-item-count">{count}</div>}
        </div>
    )
}

const NotificationsTabs = ({ tabs, tabCounts, onTabSelected }) => {
    const [activeTabCode, setActiveTabCode] = useState('all')

    const handleTabChoose = (code) => {
        setActiveTabCode(code)
    }

    useEffect(() => {
        onTabSelected(activeTabCode)
    }, [activeTabCode])

    return (
        <div className="notifications-tabs">
            {tabs.map((item, index) => (
                <Tab
                    item={item}
                    count={tabCounts[item.code]}
                    active={item.code === activeTabCode}
                    key={index}
                    onChoose={handleTabChoose}
                />
            ))}
        </div>
    )
}

export default NotificationsTabs
