import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFreeOrganizations, requestMembership } from '@/actions/organization'
import { Link } from 'react-router-dom'
import addPlus from '@/assets/images/add-plus.png'
import addSuccess from '@/assets/images/add-success.png'
import Select from 'react-select'

const PlusBody = ({ onSelect }) => {
    const dispatch = useDispatch()

    const freeOrganizations = useSelector(state => state.organization.freeOrganizations)

    const [options, setOptions] = useState([])
    const [data, setData] = useState({ organization_id: null })

    useEffect(() => {
        if (freeOrganizations.length) {
            setOptions(freeOrganizations.map(
                item => ({ value: item.id, label: item.name })
            ))
        }
    }, [freeOrganizations])

    const onInputChange = (value) => {
        dispatch(getFreeOrganizations(value))
    }

    const handleSelect = (option) => {
        const payload = { organization_id: option.value }
        setData(payload)
        onSelect(payload)
    }

    return (
        <>
            <div className="block-add-body-text">
                Znajdź organizację którą chcesz dodać do Twoich organizacji. Możesz też stworzyć<br />
                organizację a następnie przypisać do niej członków
            </div>
            <div className="block-add-body-select block-search-field">
                <div className="select-field">
                    <Select
                        className="search-select"
                        name="organization_id"
                        placeholder="wpisz nazwę lub wybierz z listy organizację którą chcesz dodać"
                        value={
                            options.find(opt => opt.value === data.organization_id)
                        }
                        options={options}
                        onInputChange={onInputChange}
                        onChange={(option) => handleSelect(option)}
                    />
                </div>
            </div>
        </>
    )
}

const PlusButtons = ({ onSubmit }) => {
    return (
        <>
            <button className="btn btn-primary" onClick={onSubmit}>
                <span>DODAJ WYBRANĄ ORGANIZACJĘ</span>
            </button>
            <Link to="/organization/create" className="btn btn-outline-primary">
                <span>UTWÓRZ ORGANIZACJĘ</span>
            </Link>
        </>
    )
}

const SuccessBody = () => {
    return (
        <div className="block-add-body-text">
            To może potrwać kilka minut lub nawet kilka dni - wszystko zależy odwłaściciela organizacji.<br />
            Jeśli nie dostaniesz odpowiedzi w przeciągu 2 dni prosimy skontakctuj się z zespołem<br />
            duxhub - <Link to="mailto:pomoc@duxhub.pl">pomoc@duxhub.pl</Link>
        </div>
    )
}

const SuccessButtons = ({ onClose }) => {
    return (
        <>
            <button className="btn btn-primary" onClick={onClose}>
                <span>ZAMKNIJ OKNO</span>
            </button>
        </>
    )
}

const BlockAdd = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFreeOrganizations())
    }, [dispatch])

    const [activeContentSlug, setActiveContentSlug] = useState('plus')
    const [activeContent, setActiveContent] = useState(null)
    const [organizationId, setOrganizationId] = useState(null)

    useEffect(() => {
        setActiveContent(content[activeContentSlug])
    }, [activeContentSlug])

    const setOrganization = (data) => {
        setOrganizationId(data.organization_id)
    }

    const addOrganization = async () => {
        if (organizationId) {
            const success = await dispatch(requestMembership({ organization_id: organizationId }))
            if (success) {
                setActiveContentSlug('success')
            }
        }
    }

    const content = {
        plus: {
            slug: 'plus',
            title: 'Dodaj organizację',
            subtitle: 'lub stwórz od początku',
            image: addPlus,
            body: <PlusBody onSelect={setOrganization} />,
            buttons: <PlusButtons onSubmit={addOrganization} />
        },
        success: {
            slug: 'success',
            title: <>Udało się!<br />Proszę poczekaj<br />na akceptację</>,
            subtitle: <>Twoja prośba o dodanie została<br />przekazana do właściciela organizacji. </>,
            image: addSuccess,
            body: <SuccessBody />,
            buttons: <SuccessButtons onClose={() => setActiveContentSlug('plus')} />
        }
    }

    return activeContent ? (
        <div className={`block block-add block-add-${activeContent.slug}`}>
            <div className="block-add-heading block-search-heading">
                <h1>{activeContent.title}</h1>
                <h2>{activeContent.subtitle}</h2>
            </div>
            <div className="block-add-image">
                <img src={activeContent.image} alt="" />
            </div>
            <div className="block-add-body">
                {activeContent.body}
            </div>
            <div className="block-add-buttons">{activeContent.buttons}</div>
        </div>
    ) : null
}

export default BlockAdd
