import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getMyOrganizations,
    deleteMember as deleteOrgMember,
    savePermissions as saveOrgPermissions
} from '@/actions/organization'
import {
    getMyCompanies,
    deleteMember as deleteComMember,
    savePermissions as saveComPermissions
} from '@/actions/company'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import DynamicLogo from '@/components/Common/Image/DynamicLogo'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getFullName } from '@/utils/user.utils'
import cog from '@/assets/images/settings/cog.svg'
import remove from '@/assets/images/settings/remove.svg'
import PermissionsPopup from './PermissionsPopup'
import MembersPopup from './MembersPopup'

const texts = {
    organization: {
        listTitle: 'moje organizacje',
        delete: 'usuń organizację',
        permissionsTitle: 'uprawnienia w organizacji'
    },
    company: {
        listTitle: 'moje firmy',
        delete: 'usuń firmę',
        permissionsTitle: 'uprawnienia w firmie'
    }
}

const List = ({ type, items, onChoose }) => {
	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

    const handleDelete = (id) => {

    }

    return (
        <div className="settings-service-list">
            <div className="settings-service-list-title">{texts[type].listTitle}</div>
            <div className="settings-service-list-items">
                {items.map((item, index) => (
                    <div className="settings-service-list-item" key={index}>
                        <div
                            className="settings-service-list-item-logo"
                            onClick={() => onChoose(item)}
                        >
                            <DynamicLogo src={getImage(item.logo_image)} />
                        </div>
                        <div
                            className="settings-service-list-item-delete"
                            onClick={handleDelete(item.id)}
                        >
                            {texts[type].delete}
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}

const MemberItem = ({ member, isShort = true, canManage, onEdit, onRemove }) => {
    const [currentMember, setCurrentMember] = useState(member)

    useEffect(() => {
        setCurrentMember(member)
    }, [member])

	const getImage = (avatar_image) => {
		if (avatar_image) {
			return `${STORAGE_URL}/${avatar_image}` 
		}
		return userDefault
	}

	return (
		<div className={`settings-service-member block-members-item ${isShort ? 'short' : ''}`}>
			<div className={`block-members-item_photo ${currentMember.profile.avatar_image ? 'block-members-item_photo-avatar' : ''}`}>
				<span>
					<DynamicLogo src={getImage(currentMember.profile.avatar_image)} />
				</span>
			</div>
			<p className="block-members-item_name">{getFullName(currentMember)}</p>
			<p className="block-members-item_profession">{currentMember.profile.position}</p>
			{canManage && (
                <div className="settings-service-member-actions block-members-item_footer">
                    <div className="settings-service-member-action">
                        <div className="settings-service-member-action-icons">
                            <div className="settings-service-member-action-icon">
                                <span>?</span>
                            </div>
                            <div
                                className="settings-service-member-action-icon"
                                onClick={() => onEdit(
                                    currentMember.id,
                                    getFullName(currentMember),
                                    currentMember.permissions
                                )}
                            >
                                <img src={cog} alt="" />
                            </div>
                        </div>
                        <div className="settings-service-member-action-caption">
                            uprawnienia
                        </div>
                    </div>
                    <div className="settings-service-member-action">
                        <div className="settings-service-member-action-icons">
                            <div
                                className="settings-service-member-action-icon"
                                onClick={() => onRemove(currentMember.id)}
                            >
                                <img src={remove} alt="" />
                            </div>
                        </div>
                        <div className="settings-service-member-action-caption">
                            usuń
                        </div>
                    </div>
			    </div>
            )}
		</div>
	)
}

const Members = ({ type, name, members, isShort = true, canManage, onEdit, onAdd, onRemove }) => {
    const [currentMembers, setCurrentMembers] = useState(members)

    useEffect(() => {
        setCurrentMembers(members)
    }, [members])

    const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        576: {
            margin: 10,
            items: Math.min(currentMembers.length, 2)
        },
        800: {
            margin: 10,
            items: Math.min(currentMembers.length, 3)
        },
        992: {
            margin: 10,
            items: Math.min(currentMembers.length, 4)
        },
        1280: {
            margin: 20,
            items: Math.min(currentMembers.length, 5)
        },
        1450: {
            margin: 20,
            items: Math.min(currentMembers.length, 5)
        }
    }

    return (
        <div className={`settings-service-members block-members-items ${isShort ? 'short' : ''}`}>
            <div className="settings-service-members-title">{texts[type].permissionsTitle} {name}</div>
            {currentMembers.length !== 0 && (
                <OwlCarousel
                    className={`owl-members owl-members-5 owl-carousel`}
                    nav={true}
                    dots={true}
                    center={false}
                    responsive={responsive}
                >
                    {currentMembers.map((member, index) => (
                        <MemberItem member={member} key={index} canManage={canManage} onEdit={onEdit} onRemove={onRemove} />
                    ))}
                </OwlCarousel>
            )}
            {canManage && (
                <div className="settings-service-members-buttons">
                    <button className="btn btn-outline-primary" onClick={onAdd}>DODAJ/USUŃ CZŁONKA +/-</button>
                </div>
            )}
        </div>
    )
}

const ServiceSettings = ({ type, entities, permissions }) => {
    const dispatch = useDispatch()

    const [currentEntity, setCurrentEntity] = useState(null)
    const [members, setMembers] = useState([])

    const onChoose = (item) => {
        console.log({ item })
        setCurrentEntity(item)
        setMembers(item.members)
    }

    useEffect(() => {
        if (entities.length) {
            onChoose(entities[0])
        } else {
            setCurrentEntity(null)
            setMembers([])
        }
    }, [type, entities])

    const [permissionsPopupOpen, setPermissionsPopupOpen] = useState(false)
    const [membersPopupOpen, setMembersPopupOpen] = useState(false)
    const [memberId, setMemberId] = useState(null)
    const [memberName, setMemberName] = useState(null)
    const [memberPermissions, setMemberPermissions] = useState([])

    useEffect(() => {
        setMemberPermissions(permissions.map(item => ({ ...item, enabled: false })))
    }, [permissions])

    const onEditPermissions = (id, fullname, perms) => {
        setMemberId(id)
        setMemberName(fullname)
        setMemberPermissions(permissions.map(item => ({ ...item, enabled: perms.includes(item.num) })))
        setPermissionsPopupOpen(true)
    }

    const onPermissionsChanged = async (newPermissions) => {
        if (memberId) {
            if (type === 'organization') {
                await dispatch(saveOrgPermissions({
                    organization_id: currentEntity.id,
                    user_id: memberId,
                    permissions: newPermissions
                }))
            } else if (type === 'company') {
                await dispatch(saveComPermissions({
                    company_id: currentEntity.id,
                    user_id: memberId,
                    permissions: newPermissions
                }))
            }
        }
        await renewCurrentEntity()
        setPermissionsPopupOpen(false)
        setMemberId(null)
        setMemberName(null)
        setMemberPermissions(permissions.map(item => ({ ...item, enabled: false })))
    }

    const onMembersSaved = async () => {
        await renewCurrentEntity()
        setMembersPopupOpen(false)
    }

    const onMemberRemove = async (user_id) => {
        if (type === 'organization') {
            dispatch(deleteOrgMember({ organization_id: currentEntity.id, user_id }))
        } else if (type === 'company') {
            dispatch(deleteComMember({ company_id: currentEntity.id, user_id }))
        }
        await renewCurrentEntity()
    }

    const renewCurrentEntity = async () => {
        if (type === 'organization') {
            const updatedOrganizations = await dispatch(getMyOrganizations())
            const updatedOrganization = updatedOrganizations.find(
                org => org.id === currentEntity?.id
            )
            if (updatedOrganization) {
                onChoose(updatedOrganization)
            }
        } else if (type === 'company') {
            const updatedCompanies = await dispatch(getMyCompanies())
            const updatedCompany = updatedCompanies.find(
                com => com.id === currentEntity?.id
            )
            if (updatedCompany) {
                onChoose(updatedCompany)
            }
        }
    }

    return (
        <div className="settings-service">
            <List
                type={type}
                items={entities}
                onChoose={onChoose}
            />
            {currentEntity && (
                <Members
                    key={`${type}-${members.map(m => m.id).join('-')}-${Date.now()}`}
                    type={type}
                    name={currentEntity?.name || null}
                    members={members}
                    canManage={currentEntity?.permissions.includes('members')}
                    onEdit={onEditPermissions}
                    onAdd={() => setMembersPopupOpen(true)}
                    onRemove={onMemberRemove}
                />
            )}
            <PermissionsPopup
                type={type}
                memberName={memberName}
                memberPermissions={memberPermissions}
                open={permissionsPopupOpen}
                onClose={() => setPermissionsPopupOpen(false)}
                onPermissionsChanged={onPermissionsChanged}
            />
            <MembersPopup
                entity={currentEntity}
                type={type}
                open={membersPopupOpen}
                onClose={() => setMembersPopupOpen(false)}
                onSubmit={onMembersSaved}
            />
        </div>
    )
}

export default ServiceSettings
