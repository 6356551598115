import SettingsPopup from './SettingsPopup'
import BlockEditOrganizationMembers from '@/components/Organization/BlockEditMembers'
import BlockEditCompanyMembers from '@/components/Company/BlockEditMembers'

const MembersPopup = ({ entity, type, open, onClose, onSubmit }) => {
    return open ? (
        <SettingsPopup
            title="Dodaj członka"
            onClose={onClose}
        >
            <div className="settings-service-popup-members">
                {type === 'organization' && (
                    <BlockEditOrganizationMembers organization={entity} onSave={onSubmit} />
                )}
                {type === 'company' && (
                    <BlockEditCompanyMembers company={entity} onSave={onSubmit} />
                )}
            </div>
        </SettingsPopup>
    ) : null
}

export default MembersPopup
