import api from './api'

class NotificationService
{
	async getNotificationTypes(token) {
		try {
			const response = await api.get('/user/notifications/types', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[NotificationService][getNotificationTypes][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getNotificationSettings(token) {
		try {
			const response = await api.get('/user/notifications/settings', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[NotificationService][getNotificationSettings][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async saveNotificationSettings(payload, token) {
		try {
			const response = await api.post('/user/notifications/save-settings', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[NotificationService][saveNotificationSettings][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getUnreadNotifications(token) {
		try {
			const response = await api.get('/user/notifications/unread', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[NotificationService][getUnreadNotifications][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getDatedNotifications(token) {
		try {
			const response = await api.get('/user/notifications/dated', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[NotificationService][getDatedNotifications][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async executeAction(url, data, token) {
		try {
			const response = await api.post(url, data, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[NotificationService][executeAction][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new NotificationService()
