import api from './api'

class CompanyService
{
	async getCompanyById(id, token) {
		try {
			const response = await api.get(`/company/one/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getCompanyById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getCompanyBySlug(slug, token) {
		try {
			const response = await api.get(`/company/one/${slug}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getCompanyBySlug][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanies(token) {
		try {
			const response = await api.get(`/company/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanies][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanyById(id, token) {
		try {
			const response = await api.get(`/company/myid/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanyById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanyBySlug(slug, token) {
		try {
			const response = await api.get(`/company/my/${slug}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanyBySlug][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async createCompany(payload, token) {
		try {
			const response = await api.post('/company/create', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][createCompany][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async uploadImage(payload, token) {
		try {
			const response = await api.post('/company/upload-image', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][uploadImage][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveInfo(payload, token) {
		try {
			const response = await api.post('/company/save-info', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][saveInfo][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveAbout(payload, token) {
		try {
			const response = await api.post('/company/save-about', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][saveAbout][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveMembers(payload, token) {
		try {
			const response = await api.post('/company/save-members', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][saveMembers][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async deleteMember(payload, token) {
		try {
			const response = await api.post('/company/delete-member', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][deleteMember][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanyOffers(slug, token) {
		try {
			const response = await api.get(`/company/my/${slug}/offers`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanyOffers][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyCompanyOffer(company_slug, offer_slug, token) {
		try {
			const response = await api.get(`/company/my/${company_slug}/offer/${offer_slug}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getMyCompanyOffer][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getPermissions(token) {
		try {
			const response = await api.get('/company/permissions', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][getPermissions][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async savePermissions(payload, token) {
		try {
			const response = await api.post('/company/save-permissions', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[CompanyService][savePermissions][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new CompanyService()
