import NotificationItem from './NotificationItem'

const Spacer = () => {
    return (
        <div className="notifications-list-dateitem-heading-spacer">
            <div className="notifications-list-dateitem-heading-spacer-point"></div>
            <div className="notifications-list-dateitem-heading-spacer-line"></div>
            <div className="notifications-list-dateitem-heading-spacer-point"></div>
        </div>
    )
}

const DateItem = ({ group }) => {
    return (
        <div className="notifications-list-dateitem">
            <div className="notifications-list-dateitem-heading">
                <Spacer />
                <span className="notifications-list-dateitem-heading-date">
                    {group.date}
                </span>
                <Spacer />
            </div>
            <div className="notifications-list-dateitem-items">
                {group.items.map((item, index) => (
                    <NotificationItem item={item} key={index} position="page" />
                ))}
            </div>
        </div>
    )
}

const NotificationsList = ({ notifications }) => {
    return (
        <div className="notifications-list">
            {notifications.map((group, index) => (
                <DateItem group={group} key={index} />
            ))}
        </div>
    )
}

export default NotificationsList
