import api from './api'

class OrganizationService
{
	async getOrganizationById(id, token) {
		try {
			const response = await api.get(`/organization/one/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getOrganizationById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getOrganizationBySlug(slug, token) {
		try {
			const response = await api.get(`/organization/one/${slug}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getOrganizationBySlug][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getFreeOrganizations(search, token) {
		try {
			const endpoint = '/organization/free' + (search ? `?search=${search}` : '')
			const response = await api.get(endpoint, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getFreeOrganizations][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyOrganizations(token) {
		try {
			const response = await api.get(`/organization/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getMyOrganizations][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyOrganizationById(id, token) {
		try {
			const response = await api.get(`/organization/myid/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getMyOrganizationById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyOrganizationBySlug(slug, token) {
		try {
			const response = await api.get(`/organization/my/${slug}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getMyOrganizationBySlug][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async createOrganization(payload, token) {
		try {
			const response = await api.post('/organization/create', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][createOrganization][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async uploadImage(payload, token) {
		try {
			const response = await api.post('/organization/upload-image', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][uploadImage][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveInfo(payload, token) {
		try {
			const response = await api.post('/organization/save-info', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][saveInfo][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveAbout(payload, token) {
		try {
			const response = await api.post('/organization/save-about', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][saveAbout][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveMembers(payload, token) {
		try {
			const response = await api.post('/organization/save-members', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][saveMembers][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async deleteMember(payload, token) {
		try {
			const response = await api.post('/organization/delete-member', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][deleteMember][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async requestMembership(payload, token) {
		try {
			const response = await api.post('/organization/membership/request', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][requestMembership][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getPermissions(token) {
		try {
			const response = await api.get('/organization/permissions', {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getPermissions][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async savePermissions(payload, token) {
		try {
			const response = await api.post('/organization/save-permissions', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][savePermissions][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new OrganizationService()
