import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getCurrentDate, reverseDateFormat } from '@/utils/datetime.utils'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import BlockNoItems from '@/components/Common/Service/BlockNoItems'

import './styles.css'

const ProjectItem = ({ project }) => {
	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	return (
		<Link to={`/project/profile/${project.slug}`} className="block-projects-item short">
			<div className="block-projects-item_image">
				<span><img src={getImage(project.logo_image)} width="" height="" alt="" /></span>
				<span><img src={getImage(project.background_image)} width="" height="" alt="" /></span>
			</div>
			<h3 className="block-projects-item_title">{project.name}</h3>
		</Link>
	)
}

const BlockDashboardProjects = ({ visible, organization, btnAction }) => {
	const [projects, setProjects] = useState([])
	const [style, setStyle] = useState({})
	const [count, setCount] = useState(null)
	const [uncompleted, setUncompleted] = useState(0)
	const [responsive, setResponsive] = useState({})

	useEffect(() => {
		if (organization) {
			setProjects(organization.projects)

			const currentDate = getCurrentDate()
			const uncompleted = organization.projects.filter(
				item => item.date_to > currentDate
			).length
			setUncompleted(uncompleted)
			const total = organization.projects.length
			setCount(`${uncompleted} z ${total}`)
		}
	}, [organization])

	useEffect(() => {
		if (visible) {
			setStyle({ display: 'block' })
		} else {
			setStyle({ display: 'none' })
		}
	}, [visible])

	useEffect(() => {
		setResponsive({
			0: {
				margin: 3,
				items: 1
			},
			576: {
				margin: 10,
				items: Math.min(projects.length, 2)
			},
			992: {
				margin: 10,
				items: Math.min(projects.length, 3)
			},
			1280: {
				margin: 10,
				items: Math.min(projects.length, 2)
			},
			1450: {
				margin: 20,
				items: Math.min(projects.length, 2)
			}
		})
	}, [projects])

	if (!projects.length) {
		return (
			<div className="block block-projects" style={style}>
				<div className="block-wrap">
					<div className="block-heading red" style={{ marginBottom: 0 }}>
						<h2>Projekty</h2>
					</div>
					<BlockNoItems text={<>
						Nie masz dodanego żadnego projektu. Klikni w buton poniżej by dodać projekt.
					</>} />
					{organization.permissions.includes('projects') && (
						<div className="block-projects-button">
							<a className="btn btn-primary" onClick={btnAction}>ZARZĄDZAJ PROJEKTAMI</a>
						</div>
					)}
				</div>
			</div>
		)
	}

	return (
		<div className="block block-projects" style={style}>
			<div className="block-wrap">
				<div className="block-heading">
					<h2>Projekty <span>{count}</span></h2>
					<p>masz aktywnych <strong>{uncompleted} projektów</strong></p>
				</div>
				<div className="block-projects-items short">
					<OwlCarousel
						className='owl-projects-short owl-carousel'
						loop
						nav
						dots={true}
						responsive={responsive}
					>
						{projects.map((project, index) => 
							<ProjectItem project={project} key={index} />
						)}						
					</OwlCarousel>
				</div>
				{organization.permissions.includes('projects') && (
					<div className="block-projects-button">
						<a className="btn btn-primary" onClick={btnAction}>ZARZĄDZAJ PROJEKTAMI</a>
					</div>
				)}
			</div>
		</div>
	)
}

export default BlockDashboardProjects
