import BlockAdd from '@/components/Organization/BlockAdd'

const OrganizationAddPage = () => {
    return (
        <>
            <BlockAdd />
        </>
    )
}

export default OrganizationAddPage
