import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { inviteContact } from '@/actions/user'
import { findDialog } from '@/actions/chat'
import { useNavigate } from 'react-router-dom'
import { getFullName } from '@/utils/user.utils'
import { workPlaces } from '@/constants/user'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import DynamicLogo from '@/components/Common/Image/DynamicLogo'
import SocialLinks from '@/components/Common/Social/SocialLinks'

import './styles.css'

const BlockUser = ({ user, isOwn, onEdit }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [images, setImages] = useState({
		background_image: null,
		avatar_image: null,
	})

	const [data, setData] = useState({
		position: '',
		area: null,
		experience: '',
		workplace: '',
		studies: '',
	})

	const [specializations, setSpecializations] = useState([])

	const [socials, setSocials] = useState([])

	const [isContact, setIsContact] = useState(false)
	const [contactStatus, setContactStatus] = useState(null)

	useEffect(() => {
		if (user) {
			const {
				background_image,
				avatar_image,
				position,
				area,
				experience,
				workplace,
				studies,
			} = user.profile

			setImages({
				background_image,
				avatar_image,
			})

			setData({
				position: position || '',
				area: area || null,
				experience: experience || '',
				workplace: workplace || '',
				studies: studies || '',
			})

			setSpecializations(user.specializations)

			setSocials(user.socials.reduce((acc, item) => {
				acc[item.platform] = item.url
				return acc
			}, {}))

			setIsContact(user.is_contact)
			setContactStatus(user.contact_status)
		}
	}, [user])

	const getImage = (type) => {
		if (user && user.profile && user.profile[`${type}_image`]) {
			return `${STORAGE_URL}/${user.profile[`${type}_image`]}` 
		}
		return type === 'avatar' ? userDefault : null
	}

	const getWorkPlaceLabel = () => {
		return user ? workPlaces[user.account_type] : 'Miejsce pracy'
	}

	const getArea = () => {
		return data.area?.name || '(brak danych)'
	}

	const getSpecializations = () => {
		return specializations.length 
			 ? specializations.map(item => item.name).join(', ') 
			 : '(brak danych)'
	}

	const getParam = (param) => {
		return data[param] || '(brak danych)'
	}

	const addContact = async () => {
		if (!isContact) {
			await dispatch(inviteContact({ contact_id: user.id }))
		}
	}

	const goChat = async () => {
		const dialogId = await dispatch(findDialog({ collocutor_id: user.id }))
		if (dialogId) {
			navigate(`/chat/${dialogId}`)
		}
	}

	return (
		<div className="block block-user">
			{isOwn && <div className="block-edit white">
				{/*<Link to="/user/edit">edytuj<span></span></Link>*/}
				<a style={{ cursor: 'pointer' }} onClick={onEdit}>edytuj<span></span></a>
			</div>}
			{images.background_image && <div className="block-wallpaper block-user_wallpaper">
				<img src={getImage('background')} width="" height="" alt="" />
			</div>}
			{!images.background_image && 
				<div
					className="block-wallpaper block-user_wallpaper"
					style={{ background: 'linear-gradient(86.54deg, #63B2E3 -1.21%, #5C8CC9 99.91%)' }}
				></div>
			}
			<div className="block-user_head">
				<div className={`block-user_head-photo ${images.avatar_image ? 'block-user_head-photo-avatar': ''}`}>
					<span>
						<DynamicLogo src={getImage('avatar')} />
					</span>
				</div>
				<div className="block-user_head-info">
					<p className="block-user_head-name">{getFullName(user)}</p>
					<p className="block-user_head-profession">{getParam('position')}</p>
				</div>
				<div className="block-user_head-soc">
					<SocialLinks socials={socials} />
				</div>
			</div>
			<div className="block-listIcons">
				<div className="block-listIcons-left">
					<ul>
						<li className="lamp"><span>Obszar rozwoju: <strong>{getArea()}</strong></span></li>
						<li className="timer"><span>Lata doświdczenia: <strong>{getParam('experience')}</strong></span></li>
						<li className="darts"><span>Specjalizacja: <strong>{getSpecializations()}</strong></span></li>
					</ul>
				</div>
				<div className="block-listIcons-right">
					<ul>
						<li className="shop"><span>{getWorkPlaceLabel()} <br /><strong>{getParam('workplace')}</strong></span></li>
						<li className="law"><span>Studia <br /><strong>{getParam('studies')}</strong></span></li>
					</ul>
				</div>
			</div>
			{!isOwn && <div className="block-user_buttons">
				<a className="btn btn-primary btn-icon-right btn-icon-star"><span>OBSERWUJ</span></a>
				<a
					className={`btn btn-outline-primary btn-icon-right btn-icon-peoples ${isContact ? 'btn-disabled' : ''}`}
					onClick={addContact}
				>
					{!isContact && <span>DODAJ DO ZNAJOMYCH</span>}
					{isContact && contactStatus === 'Pending' && <span>ZAPROSZONO DO ZNAJOMYCH</span>}
					{isContact && contactStatus === 'Accepted' && <span>DODANO DO ZNAJOMYCH</span>}
				</a>
				<a
					className="btn btn-outline-primary btn-icon-right btn-icon-mail"
					onClick={goChat}
				>
					<span>NAPISZ WIADOMOŚĆ</span>
				</a>
			</div>}
		</div>
	)
}

export default BlockUser
