import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveSettings } from '@/actions/auth'

const langs = {
    pl: 'polski'
}

const GridItem = ({ item, onChange }) => {
    const handleChange = (e) => {
        const { name, value } = e.target
        onChange({ name, value })
    }
    return (
        <div className="settings-base-grid-item">
            <div className="settings-base-grid-item-label">{item.label}</div>
            <div className="settings-base-grid-item-fields">
                {item.fields.map((field, index) => {
                    const { name, type, placeholder, value, options } = field
                    if (type.startsWith('input')) {
                        return (
                            <input
                                key={index}
                                name={name}
                                type={type.replace('input-', '')}
                                placeholder={placeholder}
                                value={value}
                                autoComplete="new-password"
                                onChange={handleChange}
                            />
                        )
                    } else if (type === 'select') {
                        return (
                            <select key={index} name={name} value={value} onChange={handleChange}>
                                {Object.entries(options).map(([v, t], oi) => (
                                    <option value={v} key={oi}>{t}</option>
                                ))}
                            </select>
                        )
                    }
                })}
            </div>
            <div className="settings-base-grid-item-note">{item.note}</div>
        </div>
    )
}

const BaseSettings = () => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        email: '', password: '', password_confirmation: '', lang: 'pl'
    })

    const user = useSelector(state => state.auth.user)

    useEffect(() => {
        if (user) {
            setData(data => ({ ...data, email: user.email }))
        }
    }, [user])

    const grid = [
        {
            label: 'adres email (login)',
            fields: [
                {
                    name: 'email',
                    type: 'input-email',
                    placeholder: 'pierwszyczłon@drugiczłon.pl',
                    value: data.email
                },
            ],
            note: 'zmień adres email (login)'
        },
        {
            label: 'nowe hasło',
            fields: [
                {
                    name: 'password',
                    type: 'input-password',
                    placeholder: 'Nowe hasło',
                    value: data.password
                },
                {
                    name: 'password_confirmation',
                    type: 'input-password',
                    placeholder: 'Powtórz hasło',
                    value: data.password_confirmation
                },
            ]
        },
        {
            label: 'język',
            fields: [
                { name: 'lang', type: 'select', value: data.lang, options: langs }
            ]
        }
    ]

    const handleChange = ({ name, value }) => {
        setData(data => ({ ...data, [name]: value }))
    }

    const onSubmit = () => {
        dispatch(saveSettings(data))
    }
    
    return (
        <div className="settings-base">
            <div className="settings-base-grid">
                {grid.map((item, index) => <GridItem item={item} key={index} onChange={handleChange} />)}
            </div>
            <div className="settings-base-buttons">
                <button className="btn btn-outline-primary">
                    <span>ZAWIEŚ KONTO</span>
                </button>
                <button className="btn btn-thirdy">
                    <span>USUŃ KONTO</span>
                </button>
                <button className="btn btn-primary" onClick={onSubmit}>
                    <span>ZAPISZ USTAWIENIA</span>
                </button>
            </div>
        </div>
    )
}

export default BaseSettings
