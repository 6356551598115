import DynamicLogo from '@/components/Common/Image/DynamicLogo'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import { reverseDateFormat } from '@/utils/datetime.utils'

const headers = [
    { title: '' },
    { title: 'temat' },
    { title: 'data złożenia' },
    { title: 'status' },
]

const ActivityHeaders = () => {
    return (
        <div className="activity-row activity-headers">
            {headers.map((header, index) => (
                <div className="activity-header" key={index}>{header.title}</div>
            ))}
        </div>
    )
}

const statuses = ['w trakcie', 'zrealizowane', 'odebrane']

const ActivityItem = ({ activity, num }) => {
    const { logo, subject, submission_date, status: activityStatus } = activity

	const getLogo = () => {
		if (logo) {
			return `${STORAGE_URL}/${logo}` 
		}
		return userDefault
	}

    return (
        <div className={`activity-row activity-item ${num % 2 === 0 ? 'activity-item-even' : 'activity-item-odd'}`}>
            <div className="activity-cell activity-cell-logo">
                <DynamicLogo src={getLogo()} />
            </div>
            <div
                className="activity-cell activity-cell-subject"
                dangerouslySetInnerHTML={{ __html: subject }}
            ></div>
            <div className="activity-cell activity-cell-date">
                {reverseDateFormat(submission_date)}
            </div>
            <div className="activity-cell activity-cell-status">
                {statuses.map((status, index) => (
                    <div
                        className={`${status === activityStatus ? 'active' : ''}`}
                        key={index}
                    >
                        {status}
                    </div>
                ))}
            </div>
        </div>
    )
}

const ActivitiesList = ({ activities }) => {
    return (
        <div className="activities-list">
            <ActivityHeaders />
            <div className="activities-items">
                {activities.map((activity, index) => (
                    <ActivityItem activity={activity} num={index + 1} key={index} />
                ))}
            </div>
        </div>
    )
}

export default ActivitiesList
