import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyOrganizations, getPermissions as getOrgPermissions } from '@/actions/organization'
import { getMyCompanies, getPermissions as getComPermissions } from '@/actions/company'
import { getNotificationTypes, getNotificationSettings } from '@/actions/notification'
import { useNavigate } from 'react-router-dom'
import DynamicLogo from '@/components/Common/Image/DynamicLogo'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import SettingsTabs from './SettingsTabs'
import BaseSettings from './BaseSettings'
import ServiceSettings from './ServiceSettings'
import NotificationsSettings from './NotificationsSettings'

import './styles.css'

const SettingsBlock = () => {
    const dispatch = useDispatch()
	const navigate = useNavigate()

    const user = useSelector(state => state.auth.user)
    const myOrganizations = useSelector(state => state.organization.myOrganizations)
    const orgPermissions = useSelector(state => state.organization.permissions)
    const myCompanies = useSelector(state => state.company.myCompanies)
    const comPermissions = useSelector(state => state.company.permissions)
    const notificationTypes = useSelector(state => state.notification.types)
    const notificationSettings = useSelector(state => state.notification.settings)

    useEffect(() => {
        dispatch(getMyOrganizations())
        dispatch(getOrgPermissions())

        dispatch(getMyCompanies())
        dispatch(getComPermissions())

        dispatch(getNotificationTypes())
        dispatch(getNotificationSettings())
    }, [dispatch])

	const getImage = (image) => {
		if (image) {
			return `${STORAGE_URL}/${image}` 
		}
		return userDefault
	}

    const goBack = () => {
        navigate(-1)
    }

    const [activeTab, setActiveTab] = useState(null)

    const tabs = [
        { title: 'USTAWIENIA PODSTAWOWE', code: 'base', component: <BaseSettings /> },
        {
            title: 'USTAWIENIA ORGANIZACJI',
            code: 'organization',
            component: <ServiceSettings type="organization" entities={myOrganizations} permissions={orgPermissions} />
        },
        {
            title: 'USTAWIENIA FIRMY',
            code: 'company',
            component: <ServiceSettings type="company" entities={myCompanies} permissions={comPermissions} />
        },
        {
            title: 'USTAWIENIA NOTYFIKACJI',
            code: 'notifications',
            component: <NotificationsSettings
                notificationTypes={notificationTypes}
                notificationSettings={notificationSettings}
            />
        },
        // { title: 'USTAWIENIA WIADOMOŚCI', code: 'messages', component: null },
    ]

    const onTabChoose = (code) => {
        console.log(code)
        const tab = tabs.find(item => item.code === code)
        setActiveTab(tab)
    }

	if (!user) return

	return (
		<div className="block block-search">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-settings-head">
                    <div className={`block-settings-head-photo ${user.profile.avatar_image ? 'block-settings-head-photo-avatar': ''}`}>
                        <span>
                            <DynamicLogo src={getImage(user.profile.avatar_image)} />
                        </span>
                    </div>
					<div className="block-settings-heading block-search-heading">
						<h1>Centrum ustawień</h1>
						<h2>Zarządzaj Ustawieniami</h2>
					</div>
				</div>
                <SettingsTabs tabs={tabs} onTabChoose={onTabChoose} />
                {activeTab && activeTab.component}
            </div>
        </div>
    )
}

export default SettingsBlock
