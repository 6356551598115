import { useState, useEffect } from 'react'

const Tab = ({ item, active, onChoose }) => {
    return (
        <div
            className={`settings-tabs-item ${active ? 'active' : ''}`}
            onClick={() => onChoose(item.code)}
        >
            <span>{item.title}</span>
        </div>
    )
}

const SettingsTabs = ({ tabs, onTabChoose }) => {
    const [activeTabCode, setActiveTabCode] = useState('base')

    useEffect(() => {
        if (activeTabCode) {
            onTabChoose(activeTabCode)
        }
    }, [activeTabCode])

    const handleTabChoose = (code) => {
        setActiveTabCode(code)
    }

    return (
        <div className="settings-tabs">
            {tabs.map((item, index) => (
                <Tab
                    item={item}
                    active={item.code === activeTabCode}
                    key={index}
                    onChoose={handleTabChoose}
                />
            ))}
        </div>
    )
}

export default SettingsTabs
