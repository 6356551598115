import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { executeAction } from '@/actions/notification'
import contact from '@/assets/images/notifications/contact.svg'
import join from '@/assets/images/notifications/join.svg'
import duxhub from '@/assets/images/notifications/duxhub.png'
import accept from '@/assets/images/notifications/accept.svg'
import decline from '@/assets/images/notifications/decline.svg'

const actionIcons = {
    accept, decline
}

// const types = {
//     'Contact invite': {
//         iconColor: '#63B2E3',
//         iconSrc: contact,
//         actionsTitle: 'ZAAKCEPTUJ',
//         acceptedTitle: 'ZAAKCEPTOWANE',
//         declinedTitle: 'ODRZUCONE'
//     },
//     'Organization membership': {
//         iconColor: '#F2D70F',
//         iconSrc: join,
//         actionsTitle: 'ZEZWÓL',
//         acceptedTitle: 'ZEZWOLONE',
//         declinedTitle: 'ZABRONIONE'
//     }
// }

const NotificationItem = ({ item, position }) => {
    const dispatch = useDispatch()
    
    const [iconColor, setIconColor] = useState('#FFFFFF')
    const [iconSrc, setIconSrc] = useState(null)
    const [actionsTitle, setActionsTitle] = useState(null)
    const [acceptedTitle, setAcceptedTitle] = useState(null)
    const [declinedTitle, setDeclinedTitle] = useState(null)

    useEffect(() => {
        switch (item.type_name) {
            case 'Contact invite':
                setIconColor('#63B2E3')
                setIconSrc(contact)
                setActionsTitle('ZAAKCEPTUJ')
                setAcceptedTitle('ZAAKCEPTOWANE')
                setDeclinedTitle('ODRZUCONE')
                break

            case 'Organization membership':
                setIconColor('#F2D70F')
                setIconSrc(join)
                setActionsTitle('ZEZWÓL')
                setAcceptedTitle('ZEZWOLONE')
                setDeclinedTitle('ZABRONIONE')
                break
        
            default:
                setIconColor('#FFFFFF')
                setIconSrc(duxhub)
                setActionsTitle('PRZECZYTANE')
                break
        }
    }, [item])

    const handleAction = (url) => {
        dispatch(executeAction(url, { ...item.data, notification_id: item.id }))
    }

    return (
        <div className={`notification-item notification-item-${position}`}>
            <div className="notification-item-icon" style={{ backgroundColor: iconColor }}>
                <img src={iconSrc} alt="" />
            </div>
            <div className="notification-item-content">
                <div className="notification-item-content-message">{item.message}</div>
                <div className="notification-item-content-actions">
                    <div className="notification-item-content-actions-title">
                        {!item.read && <span>{actionsTitle}</span>}
                        {!!item.read && item.record_status === 'Accepted' && (
                            <span className="green">{acceptedTitle}</span>
                        )}
                        {!!item.read && item.record_status === 'Declined' && (
                            <span className="red">{declinedTitle}</span>
                        )}
                    </div>
                    {!item.read && <div className="notification-item-content-actions-buttons">
                        {Object.entries(item.actions).map(([key, url], index) => (
                            <div
                                className={`notification-item-content-actions-button ${key}`}
                                key={index}
                                onClick={() => handleAction(url)}
                            >
                                <img src={actionIcons[key]} alt={key} />
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default NotificationItem
