import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveNotificationSettings } from '@/actions/notification'

const GroupTable = ({ items, onSwitch }) => {
    return (
        <table>
            <thead>
                <tr>
                    <td></td>
                    <td>włącz / wyłącz</td>
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => {
                    const { num, title, enabled } = item
                    return (
                        <tr key={index}>
                            <td>{title}</td>
                            <td>
                                <div
                                    className={`switcher`}
                                    onClick={() => onSwitch(num)}
                                >
                                    <div className={`switcher-point ${enabled ? 'enabled' : 'disabled'}`}></div>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const NotificationsSettings = ({
    notificationTypes,
    notificationSettings
}) => {
    const dispatch = useDispatch()

    const [types, setTypes] = useState([])

    useEffect(() => {
        setTypes([
            {
                num: 0,
                code: 'all',
                title: 'wszystkie powiadomienia',
                enabled: Object.values(notificationSettings).every(item => item)
            },
            ...notificationTypes.map(
                item => ({ ...item, enabled: notificationSettings[item.num] })
            )
        ])
    }, [notificationTypes, notificationSettings])

    const handleSwitch = (num) => {
        setTypes(prevTypes => {
            if (num === 0) {
                const allEnabled = !prevTypes[0].enabled
                return prevTypes.map(type => ({ ...type, enabled: allEnabled }))
            } else {
                const updatedTypes = prevTypes.map(type =>
                    type.num === num
                        ? { ...type, enabled: !type.enabled }
                        : type
                )
                
                const allEnabled = updatedTypes.slice(1).every(type => type.enabled)
                updatedTypes[0].enabled = allEnabled
    
                return updatedTypes
            }
        })
    }

    const onSubmit = () => {
        const payload = types.filter(item => item.num !== 0)
                             .map(item => ({ type: item.num, enabled: item.enabled }))
        
        dispatch(saveNotificationSettings(payload))
    }

    return (
        <div className="settings-notifications">
            <div className="settings-notifications-group">
                <div className="settings-notifications-group-title">notyfikacje na platformie</div>
                <GroupTable items={types} onSwitch={handleSwitch} />
            </div>
            <div className="settings-notifications-buttons">
                <button className="btn btn-primary" onClick={onSubmit}>
                    <span>ZAPISZ USTAWIENIA</span>
                </button>
            </div>
        </div>
    )
}

export default NotificationsSettings
