import ContentService from '@/services/content.service'

export const GET_AUTH_PAGES_SUCCESS = 'GET_AUTH_PAGES_SUCCESS'
export const GET_AUTH_PAGES_FAILURE = 'GET_AUTH_PAGES_FAILURE'
export const GET_AUTH_PAGE_SUCCESS = 'GET_AUTH_PAGE_SUCCESS'
export const GET_AUTH_PAGE_FAILURE = 'GET_AUTH_PAGE_FAILURE'
export const GET_INFO_PAGE_SUCCESS = 'GET_INFO_PAGE_SUCCESS'
export const GET_INFO_PAGE_FAILURE = 'GET_INFO_PAGE_FAILURE'
export const GET_DEVELOPMENT_AREAS_SUCCESS = 'GET_DEVELOPMENT_AREAS_SUCCESS'
export const GET_DEVELOPMENT_AREAS_FAILURE = 'GET_DEVELOPMENT_AREAS_FAILURE'
export const GET_SPECIALIZATIONS_SUCCESS = 'GET_SPECIALIZATIONS_SUCCESS'
export const GET_SPECIALIZATIONS_FAILURE = 'GET_SPECIALIZATIONS_FAILURE'
export const GET_INTERESTS_SUCCESS = 'GET_INTERESTS_SUCCESS'
export const GET_INTERESTS_FAILURE = 'GET_INTERESTS_FAILURE'

export const getAuthPagesSuccess = (data) => ({ type: GET_AUTH_PAGES_SUCCESS, payload: data })
export const getAuthPagesFailure = (error) => ({ type: GET_AUTH_PAGES_FAILURE, payload: error })
export const getAuthPageSuccess = (data) => ({ type: GET_AUTH_PAGE_SUCCESS, payload: data })
export const getAuthPageFailure = (error) => ({ type: GET_AUTH_PAGE_FAILURE, payload: error })
export const getInfoPageSuccess = (data) => ({ type: GET_INFO_PAGE_SUCCESS, payload: data })
export const getInfoPageFailure = (error) => ({ type: GET_INFO_PAGE_FAILURE, payload: error })
export const getDevelopmentAreasSuccess = (data) => ({ type: GET_DEVELOPMENT_AREAS_SUCCESS, payload: data })
export const getDevelopmentAreasFailure = (error) => ({ type: GET_DEVELOPMENT_AREAS_FAILURE, payload: error })
export const getSpecializationsSuccess = (data) => ({ type: GET_SPECIALIZATIONS_SUCCESS, payload: data })
export const getSpecializationsFailure = (error) => ({ type: GET_SPECIALIZATIONS_FAILURE, payload: error })
export const getInterestsSuccess = (data) => ({ type: GET_INTERESTS_SUCCESS, payload: data })
export const getInterestsFailure = (error) => ({ type: GET_INTERESTS_FAILURE, payload: error })

export const getAuthPages = () => async (dispatch) => {
  try {
    const result = await ContentService.getAuthPages()
    console.log(result)
    if (result.success) {
      dispatch(getAuthPagesSuccess(result.auth_pages))
    } else {
      dispatch(getAuthPagesFailure(result.message))
    }
  } catch (e) {
    dispatch(getAuthPagesFailure(e.toString()))
  }
}

export const getAuthPage = (slug) => async (dispatch) => {
  try {
    const result = await ContentService.getAuthPageBySlug(slug)
    console.log(result)
    if (result.success) {
      dispatch(getAuthPageSuccess(result.auth_page))
    } else {
      dispatch(getAuthPageFailure(result.message))
    }
  } catch (e) {
    dispatch(getAuthPageFailure(e.toString()))
  }
}

export const getInfoPage = (slug) => async (dispatch) => {
  try {
    const result = await ContentService.getInfoPageBySlug(slug)
    console.log(result)
    if (result.success) {
      dispatch(getInfoPageSuccess(result.info_page))
    } else {
      dispatch(getInfoPageFailure(result.message))
    }
  } catch (e) {
    dispatch(getInfoPageFailure(e.toString()))
  }
}

export const getDevelopmentAreas = () => async (dispatch) => {
  try {
    const result = await ContentService.getDevelopmentAreas()
    console.log(result)
    if (result.success) {
      dispatch(getDevelopmentAreasSuccess(result.areas))
    } else {
      dispatch(getDevelopmentAreasFailure(result.message))
    }
  } catch (e) {
    dispatch(getDevelopmentAreasFailure(e.toString()))
  }
}

export const getSpecializations = () => async (dispatch) => {
  try {
    const result = await ContentService.getSpecializations()
    console.log(result)
    if (result.success) {
      dispatch(getSpecializationsSuccess(result.specializations))
    } else {
      dispatch(getSpecializationsFailure(result.message))
    }
  } catch (e) {
    dispatch(getSpecializationsFailure(e.toString()))
  }
}

export const getInterests = () => async (dispatch) => {
  try {
    const result = await ContentService.getInterests()
    console.log(result)
    if (result.success) {
      dispatch(getInterestsSuccess(result.interests))
    } else {
      dispatch(getInterestsFailure(result.message))
    }
  } catch (e) {
    dispatch(getInterestsFailure(e.toString()))
  }
}
