import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDatedNotifications } from '@/actions/notification'
import { useNavigate } from 'react-router-dom'
import warning from '@/assets/images/warning.svg'
import NotificationsTabs from './NotificationsTabs'
import NotificationsList from './NotificationsList'

import './styles.css'

const NotificationsBlock = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [notifications, setNotifications] = useState([])
	const [tabNotifications, setTabNotifications] = useState({
		all: [],
		private: [],
		organization: [],
		system: [],
	})
	const [tabCounts, setTabCounts] = useState({
		all: 0,
		private: 0,
		organization: 0,
		system: 0,
	})

	const datedNotifications = useSelector(state => state.notification.datedNotifications)

	useEffect(() => {
		dispatch(getDatedNotifications())
	}, [dispatch])

    const tabs = [
        { title: 'WSZYSTKIE', code: 'all' },
        { title: 'OSOBISTE', code: 'private', types: ['Contact invite'] },
        { title: 'TWOJEJ ORGANIZCJI', code: 'organization', types: ['Organization membership'] },
        { title: 'DUXHUB', code: 'system' },
    ]	

	useEffect(() => {
		setNotifications(datedNotifications)
		for (const tab of tabs) {
			const { code, types } = tab
			let codeNotifications = []

			if (code === 'all') {
				codeNotifications = datedNotifications
			} else if (types) {
				for (const group of datedNotifications) {
					const filteredItems = group.items.filter(
						item => types.includes(item.type_name)
					)
					if (filteredItems.length) {
						codeNotifications.push({
							date: group.date,
							items: filteredItems
						})
					}
				}
			}

			const codeCount = codeNotifications.reduce((sum, group) => (
				sum + group.items.filter(item => !item.read).length
			), 0)

			setTabNotifications(prevData => ({ ...prevData, [code]: codeNotifications }))
			setTabCounts(prevData => ({ ...prevData, [code]: codeCount }))
		}
	}, [datedNotifications])

	const onTabSelected = (code) => {
		setNotifications(tabNotifications[code])
	}

    const goBack = () => {
        navigate(-1)
    }

	return (
		<div className="block block-notifications">
            <div className="block-close">
                <a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
            </div>
			<div className="block-wrap">
				<div className="block-notifications-heading">
                    <img src={warning} alt="" />
                    <span>Powiadomienia</span>
				</div>
                <div className="block-notifications-body">
                    <NotificationsTabs tabs={tabs} tabCounts={tabCounts} onTabSelected={onTabSelected} />
                    <NotificationsList notifications={notifications} />
                </div>
			</div>
		</div>
	)
}

export default NotificationsBlock
