import { useState, useEffect } from 'react'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import DynamicLogo from '@/components/Common/Image/DynamicLogo'
import SocialLinks from '@/components/Common/Social/SocialLinks'

const BlockInfo = ({ company }) => {
	const [images, setImages] = useState({
		background_image: null,
		logo_image: null,
	})

	const [data, setData] = useState({
		name: '',
		website: '',
		description: '',
		activity_type: '',
		specialization: '',
		members: 0,
	})

	const [socials, setSocials] = useState([])

	useEffect(() => {
		if (company) {
			const {
				background_image,
				logo_image,
				name,
				website,
				description,
				activity_type,
				specialization,
				members,
			} = company

			setImages({
				background_image,
				logo_image,
			})

			setData({
				name: name || '',
				website: website || '',
				description: description || '',
				activity_type: activity_type || '',
				specialization: specialization || '',
				members: members.length || 0,
			})

			setSocials(company.socials.reduce((acc, item) => {
				acc[item.platform] = item.url
				return acc
			}, {}))
		}
	}, [company])

	const getImage = (type) => {
		if (company && company[`${type}_image`]) {
			return `${STORAGE_URL}/${company[`${type}_image`]}` 
		}
		return type === 'logo' ? userDefault : null
	}

	const getParam = (param) => {
		return data[param] || '(brak danych)'
	}

	return (
		<div className="block block-company">
			{images.background_image && <div className="block-wallpaper block-company_wallpaper">
				<img src={getImage('background')} width="" height="" alt="" />
			</div>}
			{!images.background_image && 
				<div
					className="block-wallpaper block-company_wallpaper"
					style={{ background: 'linear-gradient(86.54deg, #63B2E3 -1.21%, #5C8CC9 99.91%)' }}
				></div>
			}
			<div className="block-company_head">
				<div className={`block-company_head-photo ${images.logo_image ? 'block-company_head-photo-logo': ''}`}>
					<DynamicLogo src={getImage('logo')} />
				</div>
				<p className="block-company_head-name">
					{data.name}
					<span className="block-company_head-site">
						<a href={`https://${data.website}`}><strong>{data.website}</strong></a>
					</span>
				</p>
			</div>
			<div className="block-company_desc">
				<p>{data.description}</p>
			</div>
			<div className="block-listIcons">
				<div className="block-listIcons-left">
					<ul>
						<li className="peoples"><span>wielkość organizacji: {data.members && <strong>{data.members} osób</strong>}</span></li>
						<li className="student"><span>rodzaj działalności: <strong>{getParam('activity_type')}</strong></span></li>
						<li className="muscle"><span>specjalizacja: <strong>{getParam('specialization')}</strong></span></li>
					</ul>
				</div>
			</div>
			<div className="block-company_footer">
				<div className="block-company_buttons">
					<div className="block-company_button">
						<a className="btn btn-primary btn-icon-right btn-icon-star">
							<span>OBSERWUJ</span>
						</a>
					</div>
					<p className="block-company_button-caption">organizację obserwuje 135 osób</p>
				</div>
				<div className="block-company_soc">
					<SocialLinks socials={socials} />
				</div>
			</div>
		</div>
	)
}

export default BlockInfo
