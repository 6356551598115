import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActivities } from '@/actions/user'
import { useNavigate } from 'react-router-dom'
import DynamicLogo from '@/components/Common/Image/DynamicLogo'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import ActivitiesTabs from './ActivitiesTabs'
import ActivitiesFilters from './ActivitiesFilters'
import ActivitiesList from './ActivitiesList'

import './styles.css'

const ActivitiesBlock = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

    const user = useSelector(state => state.auth.user)
    const allActivities = useSelector(state => state.user.activities)
    const activityStatuses = useSelector(state => state.user.activityStatuses)

    useEffect(() => {
        dispatch(getActivities())
    }, [dispatch])

    const [activities, setActivities] = useState([])
	const [tabActivities, setTabActivities] = useState({
		all: [],
		private: [],
		organization: [],
		system: [],
	})
	const [tabCounts, setTabCounts] = useState({
		all: 0,
		private: 0,
		organization: 0,
		system: 0,
	})

    const tabs = [
        { title: 'WSZYSTKIE', code: 'all' },
        { title: 'OSOBISTE', code: 'private' },
        { title: 'TWOJEJ ORGANIZCJI', code: 'organization', types: ['Transaction', 'Application'] },
        { title: 'DUXHUB', code: 'system' },
    ]

	useEffect(() => {
		setActivities(allActivities)
		for (const tab of tabs) {
			const { code, types } = tab
			let codeActivities = []

			if (code === 'all') {
				codeActivities = allActivities
			} else if (types) {
                codeActivities = allActivities.filter(
                    item => types.includes(item.type_name)
                )
			}

			const codeCount = codeActivities.length

			setTabActivities(prevData => ({ ...prevData, [code]: codeActivities }))
			setTabCounts(prevData => ({ ...prevData, [code]: codeCount }))
		}
	}, [allActivities])

	const onTabSelected = (code) => {
		setActivities(tabActivities[code])
	}

	const getImage = (image) => {
		if (image) {
			return `${STORAGE_URL}/${image}` 
		}
		return userDefault
	}

    const goBack = () => {
        navigate(-1)
    }

    const [filters, setFilters] = useState({
        date_from: null,
        date_to: null,
        status: null,
    })

    const onFilterSelected = (filter) => {
        const newFilters = { ...filters, ...filter }
        setFilters(newFilters)
        dispatch(getActivities(newFilters))
    }

	if (!user) return

	return (
		<div className="block block-search block-activities">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-settings-head">
                    <div className={`block-settings-head-photo ${user.profile.avatar_image ? 'block-settings-head-photo-avatar': ''}`}>
                        <span>
                            <DynamicLogo src={getImage(user.profile.avatar_image)} />
                        </span>
                    </div>
					<div className="block-settings-heading block-search-heading">
						<h1>Twoje sprawy</h1>
					</div>
				</div>
                <div className="block-activities-body">
                    <ActivitiesTabs tabs={tabs} tabCounts={tabCounts} onTabSelected={onTabSelected} />
                    <ActivitiesFilters activityStatuses={activityStatuses} onFilterSelected={onFilterSelected} />
                    <ActivitiesList activities={activities} />
                </div>
            </div>
        </div>
    )
}

export default ActivitiesBlock
