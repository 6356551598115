import { useState, useEffect } from 'react'
import SettingsPopup from './SettingsPopup'

const PermissionsPopup = ({ type, memberName, memberPermissions, open, onClose, onPermissionsChanged }) => {
    const [permissions, setPermissions] = useState(memberPermissions)

    useEffect(() => {
        setPermissions(memberPermissions)
    }, [memberPermissions])

    const handleSwitch = (num) => {
        setPermissions(prevPermissions =>
            prevPermissions.map(permission =>
                permission.num === num
                    ? { ...permission, enabled: !permission.enabled }
                    : permission
            )
        )
    }

    const onSubmit = () => {
        onPermissionsChanged(permissions)
    }

    return open ? (
        <SettingsPopup
            title={`Uprawnienia użytkownika ${memberName}`}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <div className="settings-service-popup-permissions">
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>włącz / wyłącz</td>
                        </tr>
                    </thead>
                    <tbody>
                        {permissions.map((permission, index) => {
                            const { num, name, enabled } = permission
                            return (
                                <tr key={index}>
                                    <td>{name}</td>
                                    <td>
                                        <div
                                            className={`switcher`}
                                            onClick={() => handleSwitch(num)}
                                        >
                                            <div className={`switcher-point ${enabled ? 'enabled' : 'disabled'}`}></div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </SettingsPopup>
    ) : null
}

export default PermissionsPopup
