import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { getMyProjectBySlug } from '@/actions/project'
import { getMyOrganizationBySlug } from '@/actions/organization'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockEditInfo from '@/components/Project/BlockEditInfo'
import BlockEditAbout from '@/components/Project/BlockEditAbout'
import BlockEditGoals from '@/components/Project/BlockEditGoals'
import BlockEditSupport from '@/components/Project/BlockEditSupport'
import BlockEditMembers from '@/components/Project/BlockEditMembers'
import BlockEditStages from '@/components/Project/BlockEditStages'

const ProjectEditPage = () => {
    const { slug, organization_slug } = useParams()
    const { pathname } = useLocation()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [mode, setMode] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const myProjectLoading = useSelector(state => state.project.myProjectLoading)
    const myProject = useSelector(state => state.project.myProject)
    const myOrganization = useSelector(state => state.organization.myOrganization)

    useEffect(() => {
        if (slug/* && organization_slug*/) {
            dispatch(getMyProjectBySlug(slug))
            // dispatch(getMyOrganizationBySlug(organization_slug))
            setMode('edit')
        } else if (pathname) {
            if (organization_slug && pathname.replace(`/project/organization/${organization_slug}/`, '') === 'create') {
                dispatch(getMyOrganizationBySlug(organization_slug))
                setMode('create')
            }
        }
    }, [slug, organization_slug, dispatch])

    useEffect(() => {
        if (!myProject && !myProjectLoading) {
        	navigate('/404')
        } else {
            if (myProject || mode === 'create') {
                setTimeout(() => setLoaded(true), 500)
            }
        }
    }, [myProjectLoading, myProject, mode, navigate])

	return (
		<>
            <PageLoader loaded={loaded} />
			{myOrganization && (
                <BlockEditInfo project={myProject} mode={mode} organization_id={myOrganization.id} />
            )}
			{mode === 'edit' && (<>
                <BlockEditAbout project={myProject} />
                <BlockEditGoals project={myProject} />
                <BlockEditSupport project={myProject} />
                <BlockEditMembers project={myProject} />
                <BlockEditStages project={myProject} />
            </>)}
		</>
	)
}

export default ProjectEditPage
