import close from '@/assets/images/close.svg'
import cog from '@/assets/images/settings/cog.svg'

const SettingsPopup = ({ children, title, onClose, onSubmit }) => {
    return (
        <div className="settings-service-popup-shadow">
            <div className="settings-service-popup">
                <div className="settings-service-popup-close" onClick={() => onClose()}>
                    <img src={close} alt="" />
                </div>
                <div className="settings-service-popup-title">
                    <div className="settings-service-popup-title-icon">
                        <img src={cog} alt="" />
                    </div>
                    <span>{title}</span>
                </div>
                {children}
                {onSubmit && <div className="settings-service-popup-buttons">
                    <button
                        className="btn btn-primary btn-icon-right btn-icon-check"
                        onClick={() => onSubmit()}
                    >
                        <span>ZAPISZ</span>
                    </button>
                </div>}
            </div>
        </div>
    )
}

export default SettingsPopup
