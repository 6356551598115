import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import DynamicLogo from '@/components/Common/Image/DynamicLogo'
import SocialLinks from '@/components/Common/Social/SocialLinks'
import imageStatus1 from '@/assets/images/status-compamy-1.svg'
import imageStatus2 from '@/assets/images/status-compamy-2.svg'
import imageStatus3 from '@/assets/images/status-compamy-3.svg'

const BlockDashboardInfo = ({ company }) => {
	const [images, setImages] = useState({
		background_image: null,
		logo_image: null,
	})

	const [data, setData] = useState({
		name: '',
		website: '',
		description: '',
		activity_type: '',
		specialization: '',
		studies: '',
		address: '',
		members: 0,
	})

	const [socials, setSocials] = useState([])

	useEffect(() => {
		if (company) {
			const {
				background_image,
				logo_image,
				name,
				website,
				description,
				activity_type,
				specialization,
				studies,
				address,
				members,
			} = company

			setImages({
				background_image,
				logo_image,
			})

			setData({
				name: name || '',
				website: website || '',
				description: description || '',
				activity_type: activity_type || '',
				specialization: specialization || '',
				studies: studies || '',
				address: address || '',
				members: members.length || 0,
			})

			setSocials(company.socials.reduce((acc, item) => {
				acc[item.platform] = item.url
				return acc
			}, {}))
		}
	}, [company])

	const getImage = (type) => {
		if (company && company[`${type}_image`]) {
			return `${STORAGE_URL}/${company[`${type}_image`]}` 
		}
		return type === 'logo' ? userDefault : null
	}

	const getParam = (param) => {
		return data[param] || '(brak danych)'
	}

	return (
		<div className="block block-companyDashboard">
			<div className="block-companyDashboard_wrap">
				<div className="block-companyDashboard_left">
					<div className={`block-companyDashboard-photo ${images.logo_image ? 'block-companyDashboard-photo-logo': ''}`}>
						<DynamicLogo src={getImage('logo')} />
					</div>
					{/* <div className="block-companyDashboard_status"> */}
						{/* <span><img src={imageStatus1} width="29" height="29" alt="" /></span> */}
						{/* <span><img src={imageStatus2} width="29" height="29" alt="" /></span> */}
						{/* <span><img src={imageStatus3} width="29" height="29" alt="" /></span> */}
					{/* </div> */}
				</div>
				<div className="block-companyDashboard_right">
					<p className="block-companyDashboard_name">{data.name}</p>
					<p className="block-companyDashboard_site"><a href="#"><strong>{data.website}</strong></a></p>

					<div className="block-companyDashboard_info">
						<div
							className="block-listIcons block-companyDashboard_listIcons"
							style={{ flex: 'unset', maxWidth: 'unset' }}
						>
							<ul>
								<li className="peoples">
									<span>wielkość organizacji: {data.members && <strong>{data.members} osób</strong>}</span>
								</li>
								<li className="student">
									<span>profil działalności: <strong>{data.activity_type}</strong></span>
								</li>
								<li className="muscle">
									<span>specjalizacja: <strong>{data.specialization}</strong></span>
								</li>
							</ul>
						</div>
					</div>
					{company && company.is_owner && (
						<div
							className="block-companyDashboard_edit"
							style={{ marginTop: '14px', marginLeft: '45px' }}
						>
							<Link
								to={`/company/edit/${company.slug}`}
								className="btn btn-md btn-primary"
							>
								EDYTUJ PROFIL FIRMY
							</Link>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default BlockDashboardInfo
