import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getFullName } from '@/utils/user.utils'
import { STORAGE_URL } from '@/constants/api'
import BlockNoItems from '@/components/Common/Service/BlockNoItems'
import userDefault from '@/assets/images/user-default.png'
import DynamicLogo from '@/components/Common/Image/DynamicLogo'

const MemberItem = ({ member, isShort, followButton }) => {
	const getImage = (avatar_image) => {
		if (avatar_image) {
			return `${STORAGE_URL}/${avatar_image}` 
		}
		return userDefault
	}

	return (
		<div className={`block-members-item ${isShort ? 'short' : ''}`}>
			<div className={`block-members-item_photo ${member.profile.avatar_image ? 'block-members-item_photo-avatar' : ''}`}>
				<span>
					<DynamicLogo src={getImage(member.profile.avatar_image)} />
				</span>
			</div>
			<p className="block-members-item_name">{getFullName(member)}</p>
			<p className="block-members-item_profession">{member.profile.position}</p>
			<div className="block-members-item_footer">
				<Link to={`/user/profile/${member.profile.slug}`} className="block-members-item_view">zobacz profil</Link>
				<a className="block-members-item_mail"></a>
				{followButton && 
					<a className="btn btn-sm btn-secondary btn-icon-right btn-icon-star block-members-item_follow">OBSERWUJ</a>
				}
			</div>
		</div>

	)
}

const BlockMembers = ({
	entity,
	position = 'profile',
	title,
	owlItems = 3,
	isShort = false,
	followButton = true,
	btnText = 'POZNAJ POZOSTAŁE OSOBY',
	btnAction = () => {},
	btnVisible = true
}) => {
	const [members, setMembers] = useState([])
	const [params, setParams] = useState({})
	const [responsive, setResponsive] = useState({})

	useEffect(() => {
		if (entity) {
			setMembers([ entity.owner, ...entity.members ])
		}
	}, [entity])

	useEffect(() => {
		if (owlItems === 3) {
			setParams({ dots: false })
			setResponsive({
		        0: {
		            margin: 3,
		            items: 1
		        },
		        576: {
		            margin: 10,
		            items: Math.min(members.length, 2)
		        },
		        1280: {
		            margin: 10,
		            items: Math.min(members.length, 3)
		        },
		        1450: {
		            margin: 46,
		            items: Math.min(members.length, 3)
		        }
		    })
		}
		if (owlItems === 4) {
			setParams({ dots: true })
			setResponsive({
		        0: {
		            margin: 3,
		            items: 1
		        },
		        576: {
		            margin: 10,
		            items: Math.min(members.length, 2)
		        },
		        800: {
		            margin: 10,
		            items: Math.min(members.length, 3)
		        },
		        992: {
		            margin: 10,
		            items: Math.min(members.length, 4)
		        },
		        1280: {
		            margin: 10,
		            items: Math.min(members.length, 3)
		        },
		        1450: {
		            margin: 17,
		            items: Math.min(members.length, 4)
		        }
		    })
		}
	}, [members, owlItems])

	if (!members.length) {
		return (
			<div className="block block-members">
				<div className="block-wrap">
					<div className="block-heading blue" style={{ marginBottom: 0 }}>
						<h2>{title}</h2>
					</div>
					{position === 'profile' && (
						<div
							className="block-content block-content-overflow block-lh-2"
						>(brak danych)</div>
					)}
					{position === 'dashboard' && (<>
						<BlockNoItems text={<>
							Nie masz dodanej żadnej osoby. Klikni w buton poniżej by dodać osoby do zespołu.
						</>} />
						<div className="block-members-button">
							<a className="btn btn-primary" onClick={btnAction}>{btnText}</a>
						</div>
					</>)}
				</div>
			</div>
		)
	}

	return (
		<div className="block block-members">
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>{title}</h2>
				</div>
				<div className={`block-members-items ${isShort ? 'short' : ''}`}>
					<OwlCarousel
						className={`owl-members owl-members-${owlItems} owl-carousel`}
						loop
						nav
						{...params}
						responsive={responsive}
					>
						{members.map((member, index) => 
							<MemberItem member={member} isShort={isShort} followButton={followButton} key={index} />
						)}
					</OwlCarousel>
				</div>
				{btnVisible && (
					<div className="block-members-button">
						<a className="btn btn-primary" onClick={btnAction}>{btnText}</a>
					</div>
				)}
			</div>
		</div>
	)
}

export default BlockMembers
