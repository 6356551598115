import NotificationService from '@/services/notification.service'
import { saveSuccess, saveError } from '@/utils/notify.utils'

export const GET_NOTIFICATION_TYPES_SUCCESS = 'GET_NOTIFICATION_TYPES_SUCCESS'
export const GET_NOTIFICATION_TYPES_FAILURE = 'GET_NOTIFICATION_TYPES_FAILURE'
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS'
export const GET_NOTIFICATION_SETTINGS_FAILURE = 'GET_NOTIFICATION_SETTINGS_FAILURE'
export const SAVE_NOTIFICATION_SETTINGS_SUCCESS = 'SAVE_NOTIFICATION_SETTINGS_SUCCESS'
export const SAVE_NOTIFICATION_SETTINGS_FAILURE = 'SAVE_NOTIFICATION_SETTINGS_FAILURE'
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = 'GET_UNREAD_NOTIFICATIONS_SUCCESS'
export const GET_UNREAD_NOTIFICATIONS_FAILURE = 'GET_UNREAD_NOTIFICATIONS_FAILURE'
export const GET_DATED_NOTIFICATIONS_SUCCESS = 'GET_DATED_NOTIFICATIONS_SUCCESS'
export const GET_DATED_NOTIFICATIONS_FAILURE = 'GET_DATED_NOTIFICATIONS_FAILURE'
export const EXECUTE_ACTION_SUCCESS = 'EXECUTE_ACTION_SUCCESS'
export const EXECUTE_ACTION_FAILURE = 'EXECUTE_ACTION_FAILURE'
export const SET_UNREAD_NOTIFICATIONS_COUNT = 'SET_UNREAD_NOTIFICATIONS_COUNT'

export const getNotificationTypesSuccess = (data) => ({ type: GET_NOTIFICATION_TYPES_SUCCESS, payload: data })
export const getNotificationTypesFailure = (error) => ({ type: GET_NOTIFICATION_TYPES_FAILURE, payload: error })
export const getNotificationSettingsSuccess = (data) => ({ type: GET_NOTIFICATION_SETTINGS_SUCCESS, payload: data })
export const getNotificationSettingsFailure = (error) => ({ type: GET_NOTIFICATION_SETTINGS_FAILURE, payload: error })
export const saveNotificationSettingsSuccess = () => ({ type: SAVE_NOTIFICATION_SETTINGS_SUCCESS })
export const saveNotificationSettingsFailure = (error) => ({ type: SAVE_NOTIFICATION_SETTINGS_FAILURE, payload: error })
export const getUnreadNotificationsSuccess = (data) => ({ type: GET_UNREAD_NOTIFICATIONS_SUCCESS, payload: data })
export const getUnreadNotificationsFailure = (error) => ({ type: GET_UNREAD_NOTIFICATIONS_FAILURE, payload: error })
export const getDatedNotificationsSuccess = (data) => ({ type: GET_DATED_NOTIFICATIONS_SUCCESS, payload: data })
export const getDatedNotificationsFailure = (error) => ({ type: GET_DATED_NOTIFICATIONS_FAILURE, payload: error })
export const executeActionSuccess = () => ({ type: EXECUTE_ACTION_SUCCESS })
export const executeActionFailure = (error) => ({ type: EXECUTE_ACTION_FAILURE, payload: error })
export const setUnreadNotificationsCount = (data) => ({ type: SET_UNREAD_NOTIFICATIONS_COUNT, payload: data })

export const getNotificationTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await NotificationService.getNotificationTypes(token)
    console.log(result)
    if (result.success) {
      dispatch(getNotificationTypesSuccess(result.types))
    } else {
      dispatch(getNotificationTypesFailure(result.error))
    }
  } catch (e) {
    dispatch(getNotificationTypesFailure(e.toString()))
  }
}

export const getNotificationSettings = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await NotificationService.getNotificationSettings(token)
    console.log(result)
    if (result.success) {
      dispatch(getNotificationSettingsSuccess(result.settings))
    } else {
      dispatch(getNotificationSettingsFailure(result.error))
    }
  } catch (e) {
    dispatch(getNotificationSettingsFailure(e.toString()))
  }
}

export const saveNotificationSettings = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await NotificationService.saveNotificationSettings(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveNotificationSettingsSuccess())
      saveSuccess()
    } else {
      dispatch(saveNotificationSettingsFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveNotificationSettingsFailure(e.toString()))
    saveError(e.toString())
  }
}

export const getUnreadNotifications = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await NotificationService.getUnreadNotifications(token)
    console.log(result)
    if (result.success) {
      dispatch(getUnreadNotificationsSuccess(result.notifications))
    } else {
      dispatch(getUnreadNotificationsFailure(result.error))
    }
  } catch (e) {
    dispatch(getUnreadNotificationsFailure(e.toString()))
  }
}

export const getDatedNotifications = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await NotificationService.getDatedNotifications(token)
    console.log(result)
    if (result.success) {
      dispatch(getDatedNotificationsSuccess(result.notifications))
    } else {
      dispatch(getDatedNotificationsFailure(result.error))
    }
  } catch (e) {
    dispatch(getDatedNotificationsFailure(e.toString()))
  }
}

export const executeAction = (url, data) => async (dispatch) => {
  try {
    console.log(data)
    const token = localStorage.getItem('token')
    const result = await NotificationService.executeAction(url, data, token)
    console.log(result)
    if (result.success) {
      dispatch(executeActionSuccess())
      dispatch(getUnreadNotifications())
      dispatch(getDatedNotifications())
    } else {
      dispatch(executeActionFailure(result.error))
    }
  } catch (e) {
    dispatch(executeActionFailure(e.toString()))
  }
}
